import React, {FC, useCallback} from 'react'
import {ReferredUser} from 'src/api/referralsAPI'
import {BadgeLevels, ShieldIcon, StarIcon} from 'src/components'
import {placeholderImage, range} from 'src/helpers'

export const SkeletonPreviousReferrals: FC = () => {
  return (
    <li>
      <span className="animate-pulse block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
        <div className="flex items-center px-4 py-4">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-1 relative pr-4">
              <div className="rounded-full bg-gray-400 h-12 w-12" />
            </div>
            <div className="min-w-0 flex-1 grid grid-cols-2 gap-4">
              <div>
                <div className="py-4 px-12 mb-2 bg-gray-400 rounded w-3/4" />
              </div>
              <div className="block text-right">
                <div>
                  <div className="text-sm leading-5 font-semibold text-black truncate">
                    <div className="p-4 mb-2 bg-gray-400 rounded w-3/4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </li>
  )
}

const ReferredUserCard: FC<{referral: ReferredUser}> = ({
  referral: {profile_pic, name, status, level},
}) => {
  return (
    <li>
      <span className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
        <div className="flex items-center py-4">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0 relative pr-4">
              <img
                className="h-12 w-12 rounded-full"
                src={
                  profile_pic.length >= 1
                    ? `data:image/png;base64,${profile_pic}`
                    : placeholderImage
                }
                alt=""
              />
              <span
                className={`${BadgeLevels[level]} absolute bottom-0 left-0 h-4 w-4 lg:h-6 lg:w-6 rounded-full inline-flex items-center justify-center text-white`}
              >
                {level === 'AMBASSADOR' ? (
                  <ShieldIcon width={35} height={35} fillColor="#D50F10" />
                ) : (
                  <StarIcon strokeColor="current-color" fillColor="#fff" />
                )}
              </span>
            </div>
            <div className="min-w-0 flex-1 grid grid-cols-2 gap-4">
              <div>
                <div className="text-sm leading-5 font-bold text-black truncate">
                  {name}
                </div>
              </div>
              <div className="block text-right">
                <div>
                  <div className="text-xs leading-5 font-light uppercase text-gray-700 truncate">
                    {status}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </li>
  )
}

export const PreviousReferrals: FC<{
  loading: boolean
  referrals?: Array<ReferredUser>
}> = ({loading, referrals}) => {
  const renderReferralsList = useCallback(() => {
    if (loading && !referrals)
      return range(0, 3).map(val => <SkeletonPreviousReferrals key={val} />)
    if (!referrals)
      return (
        <div className="flex justify-center">
          <p>No referrals</p>
        </div>
      )
    return referrals.map(referral => (
      <ReferredUserCard key={referral.id} referral={referral} />
    ))
  }, [referrals, loading])

  return (
    <ul className="divide-y divide-gray-400 bg-gray-100 px-4 md:px-6 rounded-lg">
      {renderReferralsList()}
    </ul>
  )
}
