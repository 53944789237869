import axiosInstance from './axiosInstance'

export type Bond = {
  id: number
  name: string
  active: number
  total_invested: number
  unit_purchased: number
  interest_rate: number
  mature_date: string // dd.mm.yyyy
  expected_valuation: number
  opputunity_type: 'charity' | 'invest'
}

export type Investor = {
  ID: string
  id: number
  level: string
  name: string
  points: number
  profile_pic: string
}

export type Series = {
  [key: string]: {
    id: number
    bond_id: string
    bond_name: string
    series_name: string
    days_left: number
    status: number
    active: number
    members: string
    returns: string
    total_raised: {estimate: number; actual: number}
    interest_payment: {annual: number; life_of_issue: number}
    location: string
    description: string
    offer: number
    tenor: number
    interest: number
    min_unit: number
    opportunity_type: string
    max_unit: number
    months_to_mature: number
    unit_price: number
    units: number
    security: string
    interest_paid: number
    start_date: string
    close_date: string
    maturity_date: string
    progress: number
  }
}

export type Opportunities = {
  display_type: number
  id: number
  bond_name: string
  image: string
  description: string
  location: string
  series: Series
}

export type Opportunity = {
  id: number
  image: string
  name: string
  description: string
  interest: number
  status: string
  active: number
  progress: number
  active_investors: number
  months_to_mature: number
  investment_raised_to_date: number
  days_left: number
  unit_price: number
  unit_remaining: number
  opportunity_type: string
  transaction_fee: number
  video_link: string
  video_type: string
}

export type BondHistory = {
  id: number
  name: string
  active: number
  total_invested: number
  unit_purchased: number
  interest_rate: number
  mature_date: string
  expected_valuation: number
  history: {
    date: string
    units_bought: string
    unit_price: string
    total_and_fee: string
  }[]
}

export type PaymentInfo = {
  bond_id: string
  unit_bought: string
  unit_price: string
  price_with_fee: number
  refrence: string
}

export type OpportunityPaymentResponse = {
  message: string
}
export interface OpportunitiesMeta {
  data: Opportunities[]
  pages: number
  total_bond: number
}

export async function fetchUserBonds(): Promise<Bond[]> {
  const {
    data: {data},
  } = await axiosInstance.get<{data: Bond[]}>('/api/user-bonds')
  return data
}

export async function fetchBondsHistory(): Promise<BondHistory[]> {
  const {
    data: {data},
  } = await axiosInstance.get<{data: BondHistory[]}>('/api/bond-history')
  return data
}

export async function fetchTopInvestors(): Promise<Investor[]> {
  const {
    data: {data},
  } = await axiosInstance.get<{data: Investor[]}>('/api/top-members')
  return data
}

export async function fetchOpportunities(
  pageNum: number,
): Promise<OpportunitiesMeta> {
  const {data} = await axiosInstance.get(`/api/view-bond?page=${pageNum}`)
  return data
}
export async function fetchOpportunity(id: string): Promise<Opportunity> {
  const {
    data: {data},
  } = await axiosInstance.get<{
    data: Opportunity[]
  }>(`/api/bond_fund?id=${id}`)
  return data[0]
}
export async function makePayment(
  paymentInfo: PaymentInfo,
): Promise<OpportunityPaymentResponse> {
  const {data} = await axiosInstance.post<OpportunityPaymentResponse>(
    `/api/fund-bond`,
    paymentInfo,
  )
  return data
}
