const theme = {
  fontFamily: {
    nunito: ['Nunito Sans, sans-serif'],
  },
  colors: {
    green: {
      '400': '#8BAE59',
    },
    gray: {
      '100': '#F5F3F3',
      '400': '#C1C1C1',
      '500': '#9c9c9c',
      '600': '#727272',
    },
    red: {
      '600': '#D50F10',
    },
    yello: {
      '600': '#D0B14D',
    },
  },
}

export {theme}
