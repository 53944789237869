import React, {FC} from 'react'
import {AccountIcon, SearchIcon} from 'src/components'
import Logo from 'src/assets/images/logo.png'
import LogoWebp from 'src/assets/images/logo.webp'
import {Link, useLocation} from 'react-router-dom'
// import styled from 'styled-components'

// const SearchListings = styled.div.attrs(() => ({
//   className: 'max-w-lg w-full lg:max-w-xs absolute right-0 bg-white ',
// }))`
//
// `

export const Header: FC = () => {
  const location = useLocation()
  const {pathname} = location
  return (
    <nav className="fixed w-full z-50 bg-white shadow">
      <div className="mx-auto">
        <div className="flex justify-between h-16">
          <div className="flex items-center lg:hidden">
            <Link
              to="/account"
              className="inline-flex items-center justify-center p-4 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <AccountIcon
                className="h-6 w-6"
                strokeWidth={40}
                strokeColor="currentColor"
              />
            </Link>
          </div>
          <div className="hidden lg:ml-8 lg:flex lg:items-center">
            <div>
              <Link to="/dashboard">
                <span
                  className={`${
                    pathname === '/dashboard'
                      ? 'border-red-600 text-gray-900 focus:border-red-800'
                      : 'border-transparent text-gray-500 focus:border-gray-300 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700'
                  } cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out`}
                >
                  Dashboard
                </span>
              </Link>
              <Link to="/bonds">
                <span
                  className={`${
                    pathname === '/bonds'
                      ? 'border-red-600 text-gray-900 focus:border-red-800'
                      : 'border-transparent text-gray-500 focus:border-gray-300 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700'
                  } ml-4 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out`}
                >
                  Bonds
                </span>
              </Link>
              <Link to="/opportunities">
                <span
                  className={`${
                    pathname === '/opportunities'
                      ? 'border-red-600 text-gray-900 focus:border-red-800'
                      : 'border-transparent text-gray-500 focus:border-gray-300 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700'
                  } ml-4 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out`}
                >
                  Opportunities
                </span>
              </Link>
              <Link to="/referrals">
                <span
                  className={`${
                    pathname === '/referrals'
                      ? 'border-red-600 text-gray-900 focus:border-red-800'
                      : 'border-transparent text-gray-500 focus:border-gray-300 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700'
                  } ml-4 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out`}
                >
                  Referrals
                </span>
              </Link>
              <Link to="/rewards">
                <span
                  className={`${
                    pathname === '/rewards'
                      ? 'border-red-600 text-gray-900 focus:border-red-800'
                      : 'border-transparent text-gray-500 focus:border-gray-300 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700'
                  } ml-4 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out`}
                >
                  Rewards
                </span>
              </Link>
              <Link to="/faqs">
                <span
                  className={`${
                    pathname === '/faqs'
                      ? 'border-red-600 text-gray-900 focus:border-red-800'
                      : 'border-transparent text-gray-500 focus:border-gray-300 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700'
                  } ml-4 cursor-pointer inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out`}
                >
                  FAQ
                </span>
              </Link>
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center px-2 lg:ml-6">
            <div className="w-1/2 md:w-1/4 lg:w-3/5 xl:w-48">
              <div>
                <picture>
                  <source srcSet={LogoWebp} type="image/webp" />
                  <source srcSet={Logo} type="image/png" />
                  <img src={Logo} alt="SoaRising" className="h-8 md:h-10" />
                </picture>
              </div>
            </div>
          </div>
          <div className="flex items-center lg:hidden">
            <Link to="/search">
              <div className="inline-flex items-center justify-center p-4 rounded-md   hover:bg-gray-100 focus:outline-none focus:bg-gray-100  transition duration-150 ease-in-out">
                <SearchIcon
                  strokeWidth={3}
                  className={`h-5 w-5 ${
                    location.pathname.includes('/search')
                      ? 'text-red-600'
                      : ' text-black'
                  } hover:text-red-600 focus:text-red-600`}
                  fillColor="currentColor"
                />
              </div>
            </Link>
          </div>
          <div className="hidden flex-1 lg:flex lg:flex-col items-center justify-center lg:mr-8 lg:justify-end">
            <div className="max-w-lg w-full lg:max-w-xs">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-black"
                    fillColor="currentColor"
                  />
                </div>
                <input
                  id="search"
                  className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm transition duration-150 ease-in-out"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
