import {isDate, parse, format} from 'date-fns'
import Cookie from 'js-cookie'

export const toFormData = (values: {[key: string]: any}): FormData => {
  const formData = new FormData()
  Object.keys(values).forEach(key => {
    formData.append(key, values[key])
  })
  return formData
}

export function getCookieValue(key: string): string | undefined {
  return Cookie.get(key)
}

const dateTimeStringCompatibility = (date: string): string => {
  let nth = 0
  return `${date.replace(/-/g, match => {
    nth += 1
    return nth < 3 ? '/' : match
  })} 00:00:00`
}

export const compatibleDate = (dob: Date): string => {
  return dateTimeStringCompatibility(format(dob, 'MM-dd-yyyy'))
}

export function parseDateString(
  _value: Date | undefined,
  originalValue: Date | string | undefined,
): Date | string | undefined {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : typeof originalValue === 'string'
    ? parse(originalValue, 'yyyy-MM-dd', new Date())
    : undefined

  return parsedDate
}

export const range = (start: number, end: number): number[] =>
  Array.from({length: end - start}, (_v, k) => k + start)

export const currencyFormatter = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
})
