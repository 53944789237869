import {createSlice, PayloadAction, createSelector} from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import {getUserStatus, loginUser} from 'src/api/authAPI'
import {AppThunk, RootState} from 'src/app/store'
import {history} from 'src/helpers'

const soarRisingToken = 'soarRisingToken'
function getUser(state: RootState): UserTokenState {
  return state.userToken
}

export const selectUserLoading = createSelector(getUser, user => user.loading)

interface UserTokenState {
  token: string
  loading: boolean
  error: string | null
}

const initialState: UserTokenState = {
  token: '',
  loading: false,
  error: null,
}

const userToken = createSlice({
  name: 'userToken',
  initialState,
  reducers: {
    logOutSuccess(state) {
      state.error = null
      state.token = ''
    },
    logOutFailure(state) {
      state.error = null
    },
    loginStart(state) {
      state.loading = true
      state.error = null
      state.token = ''
    },
    loginSuccess(state, action: PayloadAction<string>) {
      state.token = action.payload
      state.loading = false
      state.error = null
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
      state.token = ''
    },
    updateIdentificationStart(state) {
      state.loading = true
      state.error = null
    },
    updateIdentificationSuccess(state) {
      state.loading = false
      state.error = null
    },
    updateIdentificationFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    updateInvestmentTypeStart(state) {
      state.loading = true
      state.error = null
    },
    updateInvestmentTypeSuccess(state) {
      state.loading = false
      state.error = null
    },
    updateInvestmentTypeFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    updateProfilePictureStart(state) {
      state.loading = true
      state.error = null
    },
    updateProfilePictureSuccess(state) {
      state.loading = false
      state.error = null
    },
    updateProfilePictureFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  logOutFailure,
  logOutSuccess,
  loginStart,
  loginSuccess,
  loginFailure,
  updateIdentificationStart,
  updateIdentificationFailure,
  updateIdentificationSuccess,
  updateInvestmentTypeStart,
  updateInvestmentTypeFailure,
  updateInvestmentTypeSuccess,
  updateProfilePictureStart,
  updateProfilePictureFailure,
  updateProfilePictureSuccess,
} = userToken.actions
export default userToken.reducer

export const login = (
  data: FormData,
  redirectUrl: string,
): AppThunk => async dispatch => {
  try {
    dispatch(loginStart())
    const {token} = await loginUser(data)
    Cookies.set(soarRisingToken, token, {sameSite: 'Strict'})
    const {
      identification_status,
      certificate_checkbox,
      certificate_uplaod,
      investor_type,
      profile_update,
    } = await getUserStatus(token)
    dispatch(loginSuccess(token))
    if (identification_status === 0) {
      history.push('/register/step-1')
    }
    if (
      certificate_checkbox === 0 ||
      investor_type === 0 ||
      certificate_uplaod === 0
    ) {
      history.push('/register/step-2')
    }
    if (profile_update === 0) {
      history.push('/register/step-3')
    } else {
      history.push(redirectUrl)
    }
  } catch (err) {
    dispatch(loginFailure(err.error))
  }
}

export const logOutUser = (): AppThunk => dispatch => {
  console.log('bye')
  try {
    dispatch(logOutSuccess())
    Cookies.remove(soarRisingToken, {sameSite: 'Strict'})
    history.push('/login')
  } catch (error) {
    dispatch(loginFailure(error))
  }
}
