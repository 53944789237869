import React, {FC, useCallback, useState, useEffect} from 'react'
import {useStateMachine} from 'little-state-machine'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers'
import {history, toFormData, currencyFormatter} from 'src/helpers'
import {FormError} from 'src/components'
import {useDispatch} from 'react-redux'
import {
  RegisterStore,
  updateAction,
  InvestmentTypeSchema,
  InvestmentTypeData,
} from './Register'
import {updateInvestmentType} from './registerActions'
// eslint-disable-next-line max-lines-per-function
export const RegisterStepTwo: FC = () => {
  const {state, action} = useStateMachine<RegisterStore>(updateAction)
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer>('')
  const {register, handleSubmit, errors, watch} = useForm({
    defaultValues: state.data,
    resolver: yupResolver(InvestmentTypeSchema),
  })
  const dispatch = useDispatch()
  const onSubmit = handleSubmit(async (values: InvestmentTypeData) => {
    const formData = toFormData({
      investment_type: values.investment_type,
      certificate_image: imageSrc as string,
    })
    const isValid = await dispatch(updateInvestmentType(formData))
    if (((isValid as unknown) as boolean) === true) {
      action({
        ...values,
        doc_image: '',
      })

      history.push('/register/step-3')
    }
  })

  const getImageMeta = useCallback((imageFile: File): void => {
    const reader = new FileReader()
    reader.onloadend = (): void => {
      if (reader.result) setImageSrc(reader.result)
    }

    reader.readAsDataURL(imageFile)
  }, [])

  const {certificate_image, investment_type} = watch([
    'certificate_image',
    'investment_type',
  ])

  const image = certificate_image?.[0]

  useEffect(() => {
    if (image) getImageMeta(image)
  }, [getImageMeta, image])

  return (
    <section className="pt-4">
      <p className="font-bold text-gray-500">
        The FCA requires that self-certifications are renewed every 12 months.
      </p>
      <p className="font-bold pt-2">Which profile describes you best?</p>
      <form onSubmit={onSubmit} className="max-w-sm mx-auto">
        <div className="mt-4">
          <div>
            <div className="relative flex items-start">
              <div className="absolute flex items-center h-5">
                <input
                  id="one"
                  name="investment_type"
                  type="radio"
                  value="1"
                  ref={register}
                  className="form-radio h-4 w-4 text-green-500 transition duration-150 ease-in-out"
                />
              </div>
              <div className="pl-8 text-sm leading-5">
                <label htmlFor="one" className="font-medium">
                  High Networth Investor
                </label>
                <p className="text-gray-500">
                  You earn more than {currencyFormatter.format(100000)} per
                  year, or hold net assets of at least{' '}
                  {currencyFormatter.format(250000)}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="relative flex items-start">
              <div className="absolute flex items-center h-5">
                <input
                  id="two"
                  name="investment_type"
                  type="radio"
                  value="2"
                  ref={register}
                  className="form-radio h-4 w-4 text-green-500 transition duration-150 ease-in-out"
                />
              </div>
              <div className="pl-8 text-sm leading-5">
                <label htmlFor="two" className="font-medium">
                  Sophisticated Investor
                </label>
                <p className="text-gray-500">
                  You have invested in more than one unlisted company, been a
                  director of a company with an annuall turnover of at least
                  &#36;1 million, or worked in private equity in the last two
                  years, or you have been a member of a business angels network
                  for at least the last six months.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="relative flex items-start">
              <div className="absolute flex items-center h-5">
                <input
                  id="three"
                  name="investment_type"
                  type="radio"
                  value="3"
                  ref={register}
                  className="form-radio h-4 w-4 text-green-500 transition duration-150 ease-in-out"
                />
              </div>
              <div className="pl-8 text-sm leading-5">
                <label htmlFor="three" className="font-medium">
                  Everyday Investor
                </label>
                <p className="text-gray-500">
                  More than 10% of your net assets per year in shares, bonds,
                  funds or other securities that are not lsted on a stock
                  exchange. The Financial Conduct Authority refers to
                  &#39;Everyday Investors&#39; as &#39;Restricted
                  Investors&#39;.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <div className="relative flex items-start">
              <div className="absolute flex items-center h-5">
                <input
                  id="four"
                  name="investment_type"
                  value="4"
                  type="radio"
                  ref={register}
                  className="form-radio h-4 w-4 text-green-500 transition duration-150 ease-in-out"
                />
              </div>
              <div className="pl-8 text-sm leading-5">
                <label htmlFor="four" className="font-medium">
                  Not Certified
                </label>
                <p className="text-gray-500">
                  You do not meet the income, net worth or any of the
                  requirements set above
                </p>
              </div>
            </div>
          </div>
          <FormError err={errors?.investment_type?.['message']} />
        </div>
        {imageSrc ? (
          <div className="max-w-sm rounded mb-4 overflow-hidden shadow-lg">
            <img className="w-full" src={imageSrc as string} />
          </div>
        ) : null}
        {investment_type && investment_type !== '4' ? (
          <div className="mt-3">
            <label
              htmlFor="certificate_image"
              className="w-1/2 inline-flex cursor-pointer justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-red active:bg-red-600 transition duration-150 ease-in-out"
            >
              Upload ID photo
            </label>
            <p
              className={`${
                errors?.certificate_image?.['message'] ? 'inline' : 'hidden'
              } pl-8 text-sm text-center text-red-600`}
            >
              {errors?.certificate_image?.['message'] || ''}
            </p>
          </div>
        ) : null}
        <input
          id="certificate_image"
          name="certificate_image"
          type="file"
          accept="image/*"
          ref={register}
          className="hidden"
        />
        <div className="mt-3 flex items-center">
          <input
            id="acceptInvestmentRisk"
            name="acceptInvestmentRisk"
            type="checkbox"
            ref={register}
            className="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out"
          />
          <label
            htmlFor="acceptInvestmentRisk"
            className="pl-4 block text-xs md:text-sm leading-5 text-gray-900"
          >
            I accept that the investments to which the promotions will relate
            may expose me to a significant risk of losing all of the money
            invested. I am aware that it is open to me to seek advice from an
            authorised person who specialises in advising on bonds investments.
          </label>
        </div>
        <FormError err={errors?.acceptInvestmentRisk?.['message']} />
        <div className="mt-3 flex items-center">
          <input
            id="riskWarning"
            name="riskWarning"
            type="checkbox"
            ref={register}
            className="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out"
          />
          <label
            htmlFor="riskWarning"
            className="pl-4 block text-xs md:text-sm leading-5 text-gray-900"
          >
            I understand and accept the risk warning.
          </label>
        </div>
        <FormError err={errors?.riskWarning?.['message']} />
        <div className="mt-4 flex justify between">
          <span className="block w-full rounded-md shadow-sm">
            <button
              type="button"
              onClick={() => history.push('/register/step-1')}
              className="w-full flex justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-600 focus:outline-none focus:border-gray-600 focus:shadow-outline-red active:bg-gray-600 transition duration-150 ease-in-out"
            >
              Back
            </button>
          </span>
          <div className="px-2" />

          <span
            className={`block w-full rounded-md shadow-sm ${
              imageSrc ? 'mb-12' : 'mb-0'
            }`}
          >
            <button
              type="submit"
              className="w-full flex justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-red active:bg-red-600 transition duration-150 ease-in-out"
            >
              Next
            </button>
          </span>
        </div>
      </form>
    </section>
  )
}
