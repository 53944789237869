import React from 'react'
import {SvgIcon} from './SvgIcon'

export const SearchIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 1.2,
  fillColor = 'none',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    className={`stroke-current ${strokeColor} ${className}`}
  >
    <path
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m91.203 88.547l-23.273-23.27c5.3867-6.1992 8.6562-14.285 8.6562-23.125 0-19.473-15.844-35.316-35.316-35.316-19.469 0-35.309 15.844-35.309 35.316s15.84 35.316 35.312 35.316c8.8398 0 16.922-3.2695 23.121-8.6562l23.273 23.273c0.48828 0.48828 1.1289 0.73047 1.7695 0.73047s1.2773-0.24219 1.7656-0.73047c0.97656-0.98047 0.97656-2.5625 0-3.5391zm-80.242-46.395c0-16.715 13.598-30.316 30.312-30.316 16.715 0 30.316 13.602 30.316 30.316s-13.598 30.312-30.316 30.312c-16.715 0-30.312-13.598-30.312-30.312z"
    />
  </svg>
)
