import React, {FC} from 'react'
import {Bond} from 'src/api/bondsAPI'
import {Link} from 'react-router-dom'
import {currencyFormatter} from 'src/helpers'

export const SkeletonBondCard: FC = () => {
  return (
    <li className="animate-pulse col-span-1 flex flex-col bg-gray-100 rounded-lg shadow">
      <div className="flex-1 flex flex-col p-4">
        <div className="h-8 bg-gray-400 rounded w-3/4" />
      </div>
      <div>
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-xs font-light">Total Invested</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <span className="relative w-0 flex-1 flex flex-col pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-xs font-light">Units purchased</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
        </div>
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-xs font-light">Interest rate</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <span className="relative w-0 flex-1 flex flex-col pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-xs font-light">Mature date</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
        </div>
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-xs font-light">Expected valuation</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <span className="relative w-0 flex-1 flex flex-col justify-center pr-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <span className="rounded-md shadow-sm">
                <button
                  type="button"
                  disabled
                  className="w-full flex justify-center p-3 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                >
                  Buy Units
                </button>
              </span>
            </span>
          </div>
        </div>
      </div>
    </li>
  )
}

export const BondCard: FC<{bond: Bond}> = ({
  bond: {
    name,
    total_invested,
    unit_purchased,
    interest_rate,
    mature_date,
    expected_valuation,
    id,
    opputunity_type,
  },
}) => {
  return (
    <li className="col-span-1 flex flex-col bg-gray-100 rounded-lg shadow">
      <div
        className={`${
          opputunity_type === 'charity' ? '' : 'flex-1'
        } flex flex-col p-4`}
      >
        <h3 className="leading-5 uppercase font-bold">{name}</h3>
      </div>
      <div>
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-xs font-light">
                Total {opputunity_type === 'charity' ? 'Invested' : 'Donated'}
              </p>
              <p>{currencyFormatter.format(total_invested)}</p>
            </span>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <span className="relative w-0 flex-1 flex flex-col pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-xs font-light">Units purchased</p>
              <p>{unit_purchased}</p>
            </span>
          </div>
        </div>
        {opputunity_type === 'invest' ? (
          <>
            <div className="-mt-px flex">
              <div className="w-0 flex-1 flex">
                <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
                  <p className="text-xs font-light">Interest rate</p>
                  <p>{interest_rate}%</p>
                </span>
              </div>
              <div className="-ml-px w-0 flex-1 flex">
                <span className="relative w-0 flex-1 flex flex-col pb-4 leading-5 font-bold transition ease-in-out duration-150">
                  <p className="text-xs font-light">Mature date</p>
                  <p>{mature_date}</p>
                </span>
              </div>
            </div>
            <div className="-mt-px flex">
              <div className="w-0 flex-1 flex">
                <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
                  <p className="text-xs font-light">Expected valuation</p>
                  <p>{currencyFormatter.format(expected_valuation)}</p>
                </span>
              </div>
              <div className="-ml-px w-0 flex-1 flex">
                <span className="relative w-0 flex-1 flex flex-col justify-center pr-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
                  <Link to={`/opportunities/${id}`}>
                    <span className="rounded-md shadow-sm">
                      <button
                        type="button"
                        className="w-full flex justify-center p-3 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                      >
                        Buy Units
                      </button>
                    </span>
                  </Link>
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="-mt-px flex">
              <div className="w-0 flex-1 flex">
                <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
                  <p className="text-xs font-light">Project type</p>
                  <p>Charity</p>
                </span>
              </div>
            </div>
            <div className="-mt-px flex">
              <div className="-ml-px w-0 flex-1 flex">
                <span className="relative w-0 flex-1 flex flex-col justify-center px-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
                  <Link to={`/opportunities/${id}`}>
                    <span className="rounded-md shadow-sm">
                      <button
                        type="button"
                        className="w-full flex justify-center p-3 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                      >
                        Buy Units
                      </button>
                    </span>
                  </Link>
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </li>
  )
}
