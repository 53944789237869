import axiosInstance from './axiosInstance'

interface ReferFriendResponse {
  data: {
    message: string
  }
}

export type ReferredUser = {
  id: number
  profile_pic: string
  name: string
  level: string
  status: string
}

export async function fetchReferredUsers(): Promise<{
  data: Array<ReferredUser>
}> {
  const {data} = await axiosInstance.get<{data: Array<ReferredUser>}>(
    '/api/user-referred',
  )
  return data
}

export async function postReferFriend(
  referralCred: FormData,
): Promise<ReferFriendResponse> {
  const {data} = await axiosInstance.post<ReferFriendResponse>(
    '/api/invite-friend',
    referralCred,
  )
  return data
}
