import React, {FC} from 'react'
import {StarIcon, BadgeLevels, ShieldIcon} from 'src/components'
import {Investor} from 'src/api/bondsAPI'

export const SkeletonTopMembersCard: FC = () => {
  return (
    <li>
      <span className="animate-pulse block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0 relative pr-4">
              <div className="rounded-full bg-gray-400 h-12 w-12" />
            </div>
            <div className="min-w-0 flex-1 grid grid-cols-2 gap-4">
              <div>
                <div className="h-4 mb-2 bg-gray-400 rounded w-3/4" />
                <div className="flex items-center text-sm leading-5 font-light">
                  <div className="h-4 bg-gray-400 rounded w-3/4" />
                </div>
              </div>
              <div className="block text-right">
                <div>
                  <div className="text-sm leading-5 font-semibold text-black truncate">
                    <div className="h-4 mb-2 bg-gray-400 rounded w-3/4" />
                  </div>
                  <div className="text-xs leading-5 font-light">
                    <div className="h-4 bg-gray-400 rounded w-3/4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </li>
  )
}

export const TopMembersCard: FC<{investor: Investor}> = ({
  investor: {profile_pic, level, ID, points},
}) => {
  return (
    <li>
      <span className="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0 relative pr-4">
              <img
                className="h-12 w-12 rounded-full"
                src={`data:image/png;base64,${profile_pic}`}
                alt=""
              />
              <span
                className={`${
                  level === 'AMBASSADOR' ? '' : BadgeLevels[level]
                } absolute bottom-0 left-0 h-4 w-4 lg:h-6 lg:w-6 rounded-full inline-flex items-center justify-center text-white`}
              >
                {level === 'AMBASSADOR' ? (
                  <ShieldIcon width={35} height={35} fillColor="#D50F10" />
                ) : (
                  <StarIcon strokeColor="current-color" fillColor="#fff" />
                )}
              </span>
            </div>
            <div className="min-w-0 flex-1 grid grid-cols-2 gap-4">
              <div>
                <div className="text-sm leading-5 font-semibold text-black truncate">
                  ID {ID}
                </div>
                <div className="flex items-center text-sm leading-5 font-light">
                  <span className="truncate capitalize">{level}</span>
                </div>
              </div>
              <div className="block text-right">
                <div>
                  <div className="text-sm leading-5 font-semibold text-black truncate">
                    {points}
                  </div>
                  <div className="text-xs leading-5 font-light">
                    <span className="truncate">points</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </li>
  )
}
