import {yupResolver} from '@hookform/resolvers'
import React, {FC, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {RouteComponentProps, StaticContext} from 'react-router'
import Logo from 'src/assets/images/logo_with_tagline.png'
import LogoWebp from 'src/assets/images/logo_with_tagline.webp'
import {LoadingSpinner} from 'src/components'
import {toFormData} from 'src/helpers'
import styled from 'styled-components'
import * as Yup from 'yup'
import {login, selectUserLoading} from './loginSlice'

const LoginSchema = Yup.object()
  .shape({
    email: Yup.string()
      .trim()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .email('Invalid email')
      .required('Required'),
    password: Yup.string().required('Required'),
  })
  .required()

export type LoginFormData = Yup.InferType<typeof LoginSchema>

const defaultValues = {
  email: '',
  password: '',
}

const LoginContainer = styled.section`
  height: calc(100% - 2.5rem);
`

export const LoginPage: FC<RouteComponentProps<
  Record<string, string>,
  StaticContext,
  {from?: string}
>> = ({location}) => {
  const redirectUrl =
    location.state &&
    'from' in location.state &&
    typeof location.state.from === 'string'
      ? location.state.from
      : '/dashboard'

  const {register, errors, handleSubmit} = useForm({
    defaultValues,
    resolver: yupResolver(LoginSchema),
  })
  const dispatch = useDispatch()
  const [count, setCount] = useState(1)
  const isLoading = useSelector(selectUserLoading)
  const onSubmit = handleSubmit(values => {
    const formData = toFormData({...values, count: `${count}`})
    dispatch(login(formData, redirectUrl))
    setCount(prevCount => prevCount + 1)
  })

  return (
    <LoginContainer className="sm:mx-auto sm:w-full sm:max-w-md grid grid-rows-5">
      <div className="row-start-3 row-span-3 flex flex-col justify-between">
        <div className="mx-auto">
          <picture>
            <source srcSet={LogoWebp} type="image/webp" />
            <source srcSet={Logo} type="image/png" />
            <img src={Logo} alt="SoaRising" />
          </picture>
        </div>
        <form className="p-4" onSubmit={onSubmit}>
          <div>
            <input
              id="email"
              type="email"
              name="email"
              ref={register}
              placeholder="Email address"
              className="appearance-none text-black font-light bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-2"
            />
            <p
              className={`${
                errors?.email?.message ? 'block' : 'hidden'
              } mt-2 text-sm text-center text-red-600`}
            >
              {errors?.email?.message || ''}
            </p>
          </div>

          <div className="mt-2">
            <input
              id="password"
              type="password"
              name="password"
              ref={register}
              placeholder="Password"
              className="appearance-none text-black font-light bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-2"
            />
            <p
              className={`${
                errors?.password?.message ? 'block' : 'hidden'
              } mt-2 text-sm text-center text-red-600`}
            >
              {errors?.password?.message || ''}
            </p>
          </div>
          <div className="mt-6">
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <span className="block w-full rounded-md shadow-sm">
                <button
                  type="submit"
                  className="w-full flex justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-red active:bg-red-600 transition duration-150 ease-in-out"
                >
                  Login
                </button>
              </span>
            )}
          </div>
          <div className="mt-5 text-right text-sm leading-5">
            <span className="font-medium text-gray-500 hover:text-gray:400 focus:outline-none focus:underline transition ease-in-out duration-150">
              Forgot your password?
            </span>
          </div>
        </form>
      </div>
    </LoginContainer>
  )
}
