import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  fetchReferredUsers,
  postReferFriend,
  ReferredUser,
} from 'src/api/referralsAPI'
import {AppThunk, RootState} from 'src/app/store'
import {toast} from 'react-toastify'
export interface ReferredUsersState {
  data?: Array<ReferredUser>
  loading: boolean
  error: string | null
}
const initialState: ReferredUsersState = {
  data: undefined,
  loading: true,
  error: null,
}

const referrals = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
    getReferredUsersStart(state) {
      return {
        ...state,
        loading: true,
        error: null,
      }
    },
    getReferredUsersSuccess(state, action: PayloadAction<ReferredUser[]>) {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      }
    },
    getReferredUsersFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    },
    postReferUserStart(state) {
      return {
        ...state,
        loading: true,
        error: null,
      }
    },
    postReferUserFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    },
  },
})

export const {
  getReferredUsersStart,
  getReferredUsersSuccess,
  getReferredUsersFailure,
  postReferUserStart,
} = referrals.actions
export default referrals.reducer

export const getReferredUsers = (): AppThunk => async dispatch => {
  try {
    dispatch(getReferredUsersStart())
    const users = await fetchReferredUsers()
    dispatch(getReferredUsersSuccess(users.data))
  } catch (err) {
    dispatch(getReferredUsersFailure(err))
  }
}
export const referUser = (referData: FormData): AppThunk => async dispatch => {
  try {
    dispatch(postReferUserStart())
    const res = await postReferFriend(referData)
    toast.success(res.data.message)
  } catch (err) {
    dispatch(getReferredUsersFailure(err))
  }
}

/**
 * selectors
 **/
const getReferredUsersState = (state: RootState) => state.referrals

export const selectReferredUsersLoading = createSelector(
  getReferredUsersState,
  referralsStore => referralsStore.loading,
)
export const selectReferredUsers = createSelector(
  getReferredUsersState,
  referralsStore => referralsStore.data,
)
