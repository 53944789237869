import React, {FC} from 'react'
import {Route, Switch} from 'react-router'
import {StateMachineProvider, createStore} from 'little-state-machine'
import Logo from 'src/assets/images/logo.png'
import LogoWebp from 'src/assets/images/logo.webp'
import {subYears} from 'date-fns'
import * as Yup from 'yup'
import {parseDateString} from 'src/helpers'
import {RegisterStepTwo} from './StepTwo'
import {RegisterStepOne} from './StepOne'
import {RegisterStepThree} from './StepThree'

export const IdentificationStatusSchema = Yup.object()
  .shape({
    date_of_birth: Yup.date().transform(parseDateString).required('Required'),
    doc_type: Yup.string().required('Required'),
    doc_number: Yup.string().required('Required'),
    doc_image: Yup.mixed()
      .test('required', 'Required', value => {
        if (!value.length) return false
        return true
      })
      .test('fileSize', 'The file is too large', value => {
        return value?.[0]?.size <= 2000000
      }),
    address_line: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
  })
  .required()

export const InvestmentTypeSchema = Yup.object()
  .shape({
    investment_type: Yup.string().required('Required'),
    certificate_image: Yup.mixed().when('investment_type', {
      is: (val: string) => val && val !== '4',
      then: Yup.mixed()
        .test('required', 'Required', value => {
          if (!value.length) return false
          return true
        })
        .test('fileSize', 'The file is too large', value => {
          return value?.[0]?.size <= 2000000
        }),
    }),
    acceptInvestmentRisk: Yup.boolean()
      .required()
      .oneOf([true], 'Accept the conditions'),
    riskWarning: Yup.boolean()
      .required()
      .oneOf([true], 'Accept the conditions'),
  })
  .required()

export const ProfilePictureSchema = Yup.object()
  .shape({
    profile_image: Yup.mixed().when('investment_type', {
      is: (val: string) => val && val !== '4',
      then: Yup.mixed()
        .test('required', 'Required', value => {
          if (!value.length) return false
          return true
        })
        .test('fileSize', 'The file is too large', value => {
          return value?.[0]?.size <= 2000000
        }),
    }),
    termsOfUse: Yup.boolean().required().oneOf([true], 'Accept the conditions'),
    privacyPolicy: Yup.boolean()
      .required()
      .oneOf([true], 'Accept the conditions'),
  })
  .required()

export type InvestmentTypeData = Yup.InferType<typeof InvestmentTypeSchema>

export type IdentificationStatusData = Yup.InferType<
  typeof IdentificationStatusSchema
>

export type ProfilePictureData = Yup.InferType<typeof ProfilePictureSchema>

export function updateAction(
  state: {
    data: IdentificationStatusData
  },
  payload: IdentificationStatusData,
) {
  return {
    ...state,
    data: {
      ...state.data,
      ...payload,
    },
  }
}

const initState = window.sessionStorage?.['__STATE_MACHINE'] || {
  data: {
    date_of_birth: subYears(new Date(), 18),
    doc_type: '',
    doc_number: '',
    doc_image: null,
    address_line: '',
    city: '',
    country: '',
    postcode: '',
    investment_type: '',
    acceptInvestmentRisk: false,
    riskWarning: false,
    profile_image: null,
    termsOfUse: false,
    privacyPolicy: false,
  },
}

export type RegisterStore = {
  data: IdentificationStatusData & InvestmentTypeData & ProfilePictureData
}

createStore<RegisterStore>(initState)

export const RegisterPages: FC = () => {
  //TODO: useEffect to set store values on mount
  return (
    <StateMachineProvider>
      <div className="w-2/3 md:w-1/3 lg:w-1/5 pt-6 pl-6">
        <div>
          <picture>
            <source srcSet={LogoWebp} type="image/webp" />
            <source srcSet={Logo} type="image/png" />
            <img src={Logo} alt="SoaRising" className="h-12" />
          </picture>
        </div>
      </div>
      <section className="pt-6 px-4 md:px-6 lg:px-8 block h-full overflow-y-auto">
        <Switch>
          <Route exact path="/register/step-1" component={RegisterStepOne} />
          <Route exact path="/register/step-2" component={RegisterStepTwo} />
          <Route exact path="/register/step-3" component={RegisterStepThree} />
        </Switch>
      </section>
    </StateMachineProvider>
  )
}
