/* eslint-disable complexity */
import {useStateMachine} from 'little-state-machine'
import React, {FC, useCallback, useEffect, useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import {Controller, useForm} from 'react-hook-form'
import {countries, history, toFormData, compatibleDate} from 'src/helpers'
import {subYears, getTime} from 'date-fns'
import {yupResolver} from '@hookform/resolvers'
import {useDispatch} from 'react-redux'
import {FormError} from 'src/components'
import {
  updateAction,
  RegisterStore,
  IdentificationStatusSchema,
  IdentificationStatusData,
} from './Register'
import {updateIdentification} from './registerActions'

const renderCountries = (): JSX.Element[] =>
  countries.map(({name}) => (
    <option key={name} value={name}>
      {name}
    </option>
  ))

// eslint-disable-next-line max-lines-per-function
export const RegisterStepOne: FC = () => {
  const {state, action} = useStateMachine<RegisterStore>(updateAction)
  const dispatch = useDispatch()
  const {register, control, handleSubmit, watch, errors, formState} = useForm({
    defaultValues: state.data,
    resolver: yupResolver(IdentificationStatusSchema),
  })
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer>('')
  const isInvalid = (key: string): boolean => {
    return !!(
      errors[key]?.['message'] ||
      (formState.touched[key] && errors[key]?.['message'])
    )
  }

  const getImageMeta = useCallback((imageFile: File): void => {
    const reader = new FileReader()
    reader.onloadend = (): void => {
      if (reader.result) setImageSrc(reader.result)
    }

    reader.readAsDataURL(imageFile)
  }, [])

  const docImage = watch('doc_image')

  const image = docImage?.[0]

  useEffect(() => {
    if (image) getImageMeta(image)
  }, [getImageMeta, image])

  const onSubmit = handleSubmit(async (values: IdentificationStatusData) => {
    const date_of_birth = compatibleDate(values.date_of_birth as Date)
    const formData = toFormData({
      ...values,
      date_of_birth,
      doc_image: imageSrc as string,
    })
    const isValid = await dispatch(updateIdentification(formData))
    if (((isValid as unknown) as boolean) === true) {
      action({
        ...values,
        date_of_birth: getTime(new Date(date_of_birth)),
        doc_image: '',
      })
      history.push('/register/step-2')
    }
  })
  return (
    <section className="pt-8">
      <form onSubmit={onSubmit} className="max-w-sm mx-auto">
        <p className="text-gray-700 font-semibold">Identification</p>
        <section className="flex justify-between">
          <div className="flex-1 mr-4">
            <Controller
              name="date_of_birth"
              control={control}
              render={({onChange, onBlur, value}) => {
                return (
                  <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    name="date_of_birth"
                    id="date_of_birth"
                    className={`${
                      isInvalid('date_of_birth') ? 'border border-red-600' : ''
                    } appearance-none text-black font-light bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-2`}
                    placeholderText="Date of birth"
                    maxDate={subYears(new Date(), 18)}
                  />
                )
              }}
            />
            <FormError err={errors?.date_of_birth?.['message']} />
          </div>
          <div className="flex-1">
            <select
              className={`${
                isInvalid('doc_type') ? 'border border-red-600' : ''
              } my-2 appearance-none w-full p-4 bg-gray-100 rounded-lg font-light placeholder-gray-400`}
              name="doc_type"
              ref={register}
            >
              <option value="">Select option</option>
              <option value="passport">Passport</option>
              <option value="drivingLicence">Driving Licence</option>
            </select>
            <FormError err={errors?.doc_type?.['message']} />
          </div>
        </section>
        <input
          type="text"
          ref={register}
          name="doc_number"
          placeholder="Document number"
          className={`${
            isInvalid('date_of_birth') ? 'border border-red-600' : ''
          } appearance-none text-black font-light bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-4`}
        />
        <FormError
          err={errors?.doc_number?.['message']}
          classNames="-mt-2 mb-2"
        />
        {imageSrc ? (
          <div className="max-w-sm rounded mb-4 overflow-hidden shadow-lg">
            <img className="w-full" src={imageSrc as string} />
          </div>
        ) : null}
        <label
          htmlFor="doc_image"
          className="w-1/2 inline-flex cursor-pointer justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-red active:bg-red-600 transition duration-150 ease-in-out"
        >
          Upload ID photo
        </label>
        <p
          className={`${
            errors?.doc_image?.['message'] ? 'inline' : 'hidden'
          } pl-8 text-sm text-center text-red-600`}
        >
          {errors?.doc_image?.['message'] || ''}
        </p>
        <input
          id="doc_image"
          name="doc_image"
          type="file"
          accept="image/*"
          ref={register}
          className="hidden"
        />
        <p className="text-gray-700 pt-4 font-semibold">Address</p>
        <input
          ref={register}
          type="text"
          name="address_line"
          placeholder="Address line"
          className={`${
            isInvalid('address_line') ? 'border border-red-600' : ''
          } appearance-none text-black font-light bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-2`}
        />
        <FormError err={errors?.address_line?.['message']} />
        <input
          ref={register}
          type="text"
          name="city"
          placeholder="City"
          className={`${
            isInvalid('city') ? 'border border-red-600' : ''
          } appearance-none text-black font-light bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-2`}
        />
        <FormError err={errors?.city?.['message']} />
        <section className="flex justify-between">
          <div className="flex-1 mr-4">
            <input
              ref={register}
              type="text"
              name="postcode"
              placeholder="Postcode"
              className={`${
                isInvalid('postcode') ? 'border border-red-600' : ''
              } appearance-none text-black font-light bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-2`}
            />
            <FormError err={errors?.postcode?.['message']} />
          </div>
          <div className="flex-1">
            <select
              className={`${
                isInvalid('country') ? 'border border-red-600' : ''
              } my-2 appearance-none w-full p-4 bg-gray-100 rounded-lg font-light placeholder-gray-400`}
              name="country"
              ref={register}
            >
              <option value="" disabled hidden>
                Select option
              </option>
              {renderCountries()}
            </select>
            <FormError err={errors?.country?.['message']} />
          </div>
        </section>
        <div className={`mt-6 ${imageSrc ? 'mb-12' : 'mb-0'}`}>
          <span className="block w-full rounded-md shadow-sm">
            <button
              type="submit"
              className="w-full flex justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-red active:bg-red-600 transition duration-150 ease-in-out"
            >
              Next
            </button>
          </span>
        </div>
      </form>
    </section>
  )
}
