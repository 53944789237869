import React, {FC, useCallback, useEffect} from 'react'
import {Heading, MainLayout} from 'src/components'
import {useSelector, useDispatch} from 'react-redux'
import {range} from 'src/helpers'
import {
  selectOpportunities,
  selectOpportunitiesLoading,
  getOpportunities,
} from './opportunitySlice'
import {
  OpportunityDetailsCard,
  SkeletonOpportunityCard,
} from './OpportunityDetailsCard'

export const OpportunitiesPage: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getOpportunities(1))
  }, [dispatch])
  const opportunities = useSelector(selectOpportunities)
  const opportunitiesLoading = useSelector(selectOpportunitiesLoading)
  const renderOpportunitiesList = useCallback(() => {
    if (opportunitiesLoading && !opportunities)
      return range(0, 3).map(val => <SkeletonOpportunityCard key={val} />)
    if (!opportunities)
      return (
        <div className="flex justify-center">
          <p>No opportunities</p>
        </div>
      )
    return opportunities.map(opportunity => (
      <OpportunityDetailsCard key={opportunity.id} opportunity={opportunity} />
    ))
  }, [opportunities, opportunitiesLoading])
  return (
    <MainLayout>
      <section className="flex justify-between">
        <Heading text="Opportunities" />
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-6">
        {renderOpportunitiesList()}
      </section>
    </MainLayout>
  )
}
