import React from 'react'
import {SvgIcon} from './SvgIcon'

const CloseIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'none',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    className={`stroke-current ${strokeColor} ${className}`}
    viewBox="0 0 100 100"
    version="1.1"
    x="0px"
    y="0px"
  >
    <path d=" M 5.414 11.999 C 1.087 7.673 7.673 1.109 11.999 5.414 L 50 43.414 L 88.001 5.414 C 92.327 1.087 98.913 7.673 94.586 11.999 L 56.586 50 L 94.586 88.001 C 98.913 92.327 92.327 98.913 88.001 94.586 L 50 56.586 L 11.999 94.586 C 7.673 98.913 1.087 92.327 5.414 88.001 L 43.414 50 L 5.414 11.999 Z " />
  </svg>
)

export {CloseIcon}
