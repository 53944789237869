import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit'
import userReducer from '../features/login/loginSlice'
import bondsReducer from '../features/dashboard/dashboardSlice'
import faqReducer from '../features/faq/faqsSlice'
import accountReducer from '../features/account/accountSlice'
import opportunityReducer from '../features/opportunities/opportunitySlice'
import referralsReducer from '../features/referrals/referralsSlice'
import rewardsReducer from '../features/rewards/rewardsSlice'
import searchReducer from '../features/search/searchSlice'

export const store = configureStore({
  reducer: {
    userToken: userReducer,
    userOpportunities: opportunityReducer,
    userBonds: bondsReducer,
    userProfile: accountReducer,
    faq: faqReducer,
    referrals: referralsReducer,
    rewards: rewardsReducer,
    search: searchReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
