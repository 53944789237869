import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {Opportunities} from 'src/api/bondsAPI'
import {currencyFormatter} from 'src/helpers'
import styled from 'styled-components'

const CenteredText = styled.h2`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const SkeletonInvestmentCard: FC = () => {
  return (
    <li className="animate-pulse col-span-1 flex flex-col bg-gray-100 rounded-lg shadow">
      <div className="flex">
        <div className="relative flex-1">
          <img
            className="h-56 w-full object-cover"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Townhouses_in_Victoria_Australia.jpg/220px-Townhouses_in_Victoria_Australia.jpg"
            alt=""
          />
          <span className="absolute left-0 w-24 top-0 inline-flex items-center mt-3 ml-3 px-3 py-1 rounded-full text-xs font-medium leading-4 bg-red-600 text-white">
            <div className="h-4 bg-gray-400 rounded w-full" />
          </span>
          <span className=" absolute top-0 w-20 right-0 inline-flex items-center mt-3 mr-3 px-3 py-1 rounded-full text-xs font-medium leading-4 bg-red-600 text-white">
            <div className="h-4 bg-gray-400 rounded w-full" />
          </span>
          <CenteredText className="my-0 text-white font-semibold font-xl">
            <div className="h-4 bg-gray-400 rounded w-3/4" />
          </CenteredText>
        </div>
      </div>
      <div className="w-full">
        <div className="shadow w-full bg-grey-500 h-5">
          <div
            className="bg-gray-500 text-xs leading-none h-5 py-1 text-center text-white"
            style={{
              width: `${100}%`,
            }}
          />
        </div>
      </div>
      <div className="text-xs py-3">
        <div className="-mt-px leading-5 px-3 font-bold grid grid-cols-4 grid-rows-1">
          <p className="pr-1 font-light">Months to mature</p>
          <p className="px-1 font-light">Investment raised to date</p>
          <p className="px-1 font-light">Active investors</p>
          <p className="px-1 font-light">Days left</p>
        </div>
        <div className="-mt-px leading-5 px-3 font-bold grid grid-cols-4 grid-rows-1">
          <div className="pr-1 h-4 bg-gray-400 rounded w-3/4" />
          <div className="px-1 h-4 bg-gray-400 rounded w-3/4" />
          <div className="px-1 h-4 bg-gray-400 rounded w-3/4" />
          <div className="px-1 h-4 bg-gray-400 rounded w-3/4" />
        </div>
      </div>
    </li>
  )
}

export const InvestmentCard: FC<{opportunity: Opportunities}> = ({
  opportunity: {image, bond_name, series, id},
}) => {
  const firstSeries = series && series[Object.keys(series)[0]]
  return (
    <li className="col-span-1 flex flex-col bg-gray-100 rounded-lg shadow">
      <Link to={`/opportunities/${id}`}>
        <div className="flex">
          <div className="relative flex-1">
            <img
              className="h-56 w-full rounded-t-lg object-cover"
              src={image}
              alt=""
            />
            <span
              className={`absolute left-0 top-0 inline-flex items-center mt-3 ml-3 px-3 py-1 rounded-full text-xs font-medium leading-4 ${
                firstSeries?.opportunity_type === 'charity'
                  ? 'bg-yellow-500 text-black'
                  : 'bg-red-600 text-white'
              }`}
            >
              {firstSeries === undefined
                ? 'None'
                : firstSeries.opportunity_type === 'charity'
                ? 'Charity'
                : `Investment - ${firstSeries.interest}% interest`}
            </span>
            <span className=" absolute top-0 right-0 inline-flex items-center mt-3 mr-3 px-3 py-1 rounded-full text-xs font-medium leading-4 bg-red-600 text-white">
              {firstSeries?.status ? 'active' : 'inactive'}
            </span>
            <CenteredText className="my-0 text-white font-semibold font-xl">
              {bond_name}
            </CenteredText>
          </div>
        </div>
        <div className="w-full">
          <div className="shadow w-full bg-grey-500 h-5">
            <div
              className="bg-gray-500 text-xs leading-none h-5 py-1 text-center text-white"
              style={{
                width: `${
                  firstSeries?.progress
                    ? firstSeries?.progress > 100
                      ? 100
                      : firstSeries?.progress
                    : 0
                }%`,
              }}
            >
              <span>{firstSeries?.progress.toFixed(2) || 0}%</span>
            </div>
          </div>
        </div>
        {firstSeries ? (
          <div className="text-xs py-3">
            <div className="-mt-px leading-5 px-3 font-bold grid grid-cols-5 grid-rows-1">
              <p className="pr-1 font-light">Months to mature</p>
              <p className="px-1 col-span-2 font-light">
                Investment raised to date
              </p>
              <p className="px-1 font-light">Active investors</p>
              <p className="px-1 font-light">Days left</p>
            </div>
            <div className="-mt-px leading-5 px-3 font-bold grid grid-cols-5 grid-rows-1">
              <p className="pr-1">{firstSeries.months_to_mature}</p>
              <p className="px-1 col-span-2">
                {currencyFormatter.format(firstSeries.total_raised.actual)}
              </p>
              <p className="px-1">{firstSeries.members}</p>
              <p className="px-1">{firstSeries.days_left}</p>
            </div>
          </div>
        ) : (
          <div className="flex pt-4 justify-center items-center">
            <p>No opportunities</p>
          </div>
        )}
      </Link>
    </li>
  )
}
