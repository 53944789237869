import React, {FC} from 'react'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {
  ChatBubbleIcon,
  FAQIcon,
  HomeIcon,
  StatsChartIcon,
  TrendingUpIcon,
  TrophyIcon,
} from '../svg'

export const Footer: FC = () => {
  const location = useLocation()
  const {pathname} = location
  return (
    <footer className="absolute z-50 inset-x-0 bottom-0 bg-white lg:hidden">
      <div className="flex flex-row flex-no-wrap justify-around md:px-48 items-center h-12">
        <Link to="/dashboard">
          <HomeIcon
            strokeColor={pathname === '/dashboard' ? 'text-red-600' : undefined}
          />
        </Link>
        <Link to="/bonds">
          <TrendingUpIcon
            strokeColor={pathname === '/bonds' ? 'text-red-600' : undefined}
          />
        </Link>
        <Link to="/opportunities">
          <StatsChartIcon
            strokeColor={
              pathname === '/opportunities' ? 'text-red-600' : undefined
            }
          />
        </Link>
        <Link to="/referrals">
          <ChatBubbleIcon
            strokeColor={pathname === '/referrals' ? 'text-red-600' : undefined}
          />
        </Link>
        <Link to="/rewards">
          <TrophyIcon
            strokeColor={pathname === '/rewards' ? 'text-red-600' : undefined}
          />
        </Link>
        <Link to="/faqs">
          <FAQIcon
            strokeColor={pathname === '/faqs' ? 'text-red-600' : undefined}
          />
        </Link>
      </div>
    </footer>
  )
}
