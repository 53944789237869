import React, {FC} from 'react'
import Loader from 'react-loader-spinner'
import {theme} from 'src/styles'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

type Props = {
  height?: number
  width?: number
  type?: 'Grid' | 'Rings'
}

export const LoadingSpinner: FC<Props> = ({
  height = 30,
  width = 30,
  type = 'Grid',
}) => {
  return (
    <div className="flex justify-center">
      <Loader
        type={type}
        color={theme.colors.red['600']}
        height={height}
        width={width}
      />
    </div>
  )
}
