import React, {FC} from 'react'

export const FormError: FC<{err?: string; classNames?: string}> = ({
  err,
  classNames,
}) => (
  <p
    className={`${
      err ? 'block' : 'hidden'
    } ${classNames} text-sm text-center text-red-600`}
  >
    {err || ''}
  </p>
)
