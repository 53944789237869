import React from 'react'
import {SvgIcon} from './SvgIcon'

export const StatsChartIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${className}`}
  >
    <rect
      x="64"
      y="320"
      width="48"
      height="160"
      rx="8"
      ry="8"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <rect
      x="288"
      y="224"
      width="48"
      height="256"
      rx="8"
      ry="8"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <rect
      x="400"
      y="112"
      width="48"
      height="368"
      rx="8"
      ry="8"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
    <rect
      x="176"
      y="32"
      width="48"
      height="448"
      rx="8"
      ry="8"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  </svg>
)
