import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {fetchFAQs, fetchSingleFAQ} from 'src/api/faqAPI'
import {AppThunk} from 'src/app/store'
import {FAQ, FAQState} from './types'

const initialState: FAQState = {
  current: undefined,
  currentLoading: false,
  data: [],
  loading: false,
  error: null,
}

const faq = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    faqStart(state) {
      state.loading = true
      state.error = null
      state.data = []
    },
    faqSuccess(state, action: PayloadAction<Array<Omit<FAQ, 'description'>>>) {
      state.data = action.payload
      state.loading = false
      state.error = null
    },
    faqFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    currentFaqStart(state) {
      state.currentLoading = true
      state.error = null
      state.current = undefined
    },
    currentFaqSuccess(state, action: PayloadAction<FAQ>) {
      state.current = action.payload
      state.currentLoading = false
      state.error = null
    },
    currentFaqFailure(state, action: PayloadAction<string>) {
      state.currentLoading = false
      state.error = action.payload
    },
  },
})

export const {
  faqStart,
  faqSuccess,
  faqFailure,
  currentFaqStart,
  currentFaqSuccess,
  currentFaqFailure,
} = faq.actions
export default faq.reducer

export const getFaqs = (): AppThunk => async dispatch => {
  try {
    dispatch(faqStart())
    const faqs = await fetchFAQs()
    dispatch(faqSuccess(faqs.data))
  } catch (err) {
    dispatch(faqFailure(err))
  }
}
export const getCurrentFaq = (id: string): AppThunk => async dispatch => {
  try {
    dispatch(currentFaqStart())
    const currentFaq = await fetchSingleFAQ(id)
    dispatch(currentFaqSuccess(currentFaq.data[0]))
  } catch (err) {
    dispatch(currentFaqFailure(err))
  }
}
