import {createSelector} from '@reduxjs/toolkit'
import {RootState} from 'src/app/store'

const getAuthStore = (state: RootState) => state.userProfile

export const selectAuthProfile = createSelector(
  getAuthStore,
  userProfile => userProfile.data,
)
export const selectAuthProfileLoading = createSelector(
  getAuthStore,
  userProfile => userProfile.loading,
)
