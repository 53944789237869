import React, {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {BondsPage} from 'src/features/bonds'
import {DashboardPage} from 'src/features/dashboard'
import {FAQPage} from 'src/features/faq'
import {AccountPage} from 'src/features/account'
import {LoginPage} from 'src/features/login'
import {RewardsPage} from 'src/features/rewards'
import {ReferralsPage} from 'src/features/referrals'
import {
  OpportunitiesPage,
  SingleOpportunitiesPage,
} from 'src/features/opportunities'
import {RegisterPages} from 'src/features/register'
import {SearchPage} from 'src/features/search'
import PrivateRoute from 'src/HOC/PrivateRoute'
import PageNotFound from 'src/features/PageNotFound'

export const AllRoutes: FC = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <Route exact path="/login" component={LoginPage} />
      <PrivateRoute path="/register" component={RegisterPages} />
      <PrivateRoute exact path="/search" component={SearchPage} />
      <PrivateRoute exact path="/account" component={AccountPage} />
      <PrivateRoute exact path="/faqs" component={FAQPage} />
      <PrivateRoute exact path="/rewards" component={RewardsPage} />
      <PrivateRoute exact path="/referrals" component={ReferralsPage} />
      <PrivateRoute exact path="/dashboard" component={DashboardPage} />
      <PrivateRoute exact path="/rewards" component={RewardsPage} />
      <PrivateRoute exact path="/bonds" component={BondsPage} />
      <PrivateRoute exact path="/opportunities" component={OpportunitiesPage} />
      <PrivateRoute
        exact
        path="/opportunities/:id"
        component={SingleOpportunitiesPage}
      />
      <Route component={PageNotFound} />
    </Switch>
  )
}
