import React, {FC} from 'react'
import {currencyFormatter} from 'src/helpers'

export const BondPurchaseHistory: FC<{
  bondHistory: {
    date: string
    units_bought: string
    unit_price: string
    total_and_fee: string
  }[]
}> = ({bondHistory}) => {
  const {totalBought, totalCost} = bondHistory.reduce(
    (acc, curr) => {
      acc.totalBought += parseInt(curr.units_bought, 10)
      acc.totalCost += parseInt(curr.unit_price, 10)
      return acc
    },
    {
      totalBought: 0,
      totalCost: 0,
    },
  )
  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full overflow-hidden border-t-2 border-white">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="pr-4 py-2 border-b-2 border-white text-left text-xs leading-4 font-medium text-gray-800 tracking-wider">
                  Date bought
                </th>
                <th className="px-4 py-2 border-b-2 border-white text-left text-xs leading-4 font-medium text-gray-800 tracking-wider">
                  Units bought
                </th>
                <th className="px-4 py-2 border-b-2 border-white text-left text-xs leading-4 font-medium text-gray-800 tracking-wider">
                  Unit price
                </th>
                <th className="pl-4 py-2 border-b-2 border-white text-left text-xs leading-4 font-medium text-gray-800 tracking-wider">
                  Total + fee
                </th>
              </tr>
            </thead>
            <tbody>
              {bondHistory.map(
                ({date, units_bought, unit_price, total_and_fee}, index) => (
                  <tr key={`${date}${index}`}>
                    <td className="pr-4 py-2 whitespace-no-wrap font-light border-b-2 border-white text-sm leading-5 text-gray-900">
                      {date}
                    </td>
                    <td className="px-4 py-2 whitespace-no-wrap font-light border-b-2 border-white text-sm leading-5 text-gray-900">
                      {units_bought}
                    </td>
                    <td className="px-4 py-2 whitespace-no-wrap font-light border-b-2 border-white text-sm leading-5 text-gray-900">
                      {currencyFormatter.format(parseInt(unit_price, 10))}
                    </td>
                    <td className="pl-4 py-2 whitespace-no-wrap font-light border-b-2 border-white text-sm leading-5 text-gray-900">
                      {currencyFormatter.format(parseInt(total_and_fee, 10))}
                    </td>
                  </tr>
                ),
              )}
              <tr>
                <td className="px-4 py-2 whitespace-no-wrap font-bold text-sm leading-5 text-gray-900">
                  Total
                </td>
                <td className="px-4 py-2 whitespace-no-wrap font-bold text-sm leading-5 text-gray-900">
                  {totalBought}
                </td>
                <td />
                <td className="px-4 py-2 whitespace-no-wrap font-bold text-sm leading-5 text-gray-900">
                  {currencyFormatter.format(totalCost * totalBought)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
