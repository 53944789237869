import React, {FC, useEffect} from 'react'
import {Heading, MainLayout} from 'src/components'
import {useDispatch, useSelector} from 'react-redux'
import {useRouteMatch} from 'react-router'
import {OpportunityInvestmentCard} from './OpportunityInvestment'
import {
  getOpportunity,
  // selectOpportunitiesLoading,
  selectOpportunity,
  clearOpportunity,
} from './opportunitySlice'
import {SkeletonOpportunityCard} from './OpportunityDetailsCard'

export const SingleOpportunitiesPage: FC = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch<{id: string}>()
  useEffect(() => {
    dispatch(getOpportunity(match.params.id))
    return () => {
      dispatch(clearOpportunity(undefined))
    }
  }, [dispatch, match.params.id])
  const opportunity = useSelector(selectOpportunity)
  // const opportunityLoading = useSelector(selectOpportunitiesLoading)
  return (
    <MainLayout>
      <section
        className={`${opportunity ? '' : 'animate-pulse'} flex justify-center`}
      >
        {opportunity ? (
          <Heading text={opportunity.name} />
        ) : (
          <div className="h-4 bg-gray-400 rounded w-1/2" />
        )}
      </section>
      <section className="flex flex-col space-y-4 md:space-x-4 md:space-y-0 md:flex-row pt-6">
        {opportunity ? (
          <OpportunityInvestmentCard
            opportunity={opportunity}
            bondId={match.params.id}
          />
        ) : (
          <SkeletonOpportunityCard />
        )}
      </section>
    </MainLayout>
  )
}
