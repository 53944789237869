import React from 'react'
import {SvgIcon} from './SvgIcon'

export const ChevronRightIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'none',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`${strokeColor} ${className}`}
  >
    <path d="M9.707 18.707l6-6c0.391-0.391 0.391-1.024 0-1.414l-6-6c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5.293 5.293-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0z" />
  </svg>
)
