import React from 'react'
import {SvgIcon} from './SvgIcon'

export const AccountIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={`stroke-current ${strokeColor} ${className}`}
  >
    <path
      d="M344,144c-3.92,52.87-44,96-88,96s-84.15-43.12-88-96c-4-55,35-96,88-96S348,90,344,144Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M256,304c-87,0-175.3,48-191.64,138.6C62.39,453.52,68.57,464,80,464H432c11.44,0,17.62-10.48,15.65-21.4C431.3,352,343,304,256,304Z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
