import * as Yup from 'yup'

export const AccountSchema = Yup.object()
  .shape({
    address_line: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    currency: Yup.string(),
    doc_image: Yup.string(),
    doc_number: Yup.string(),
    doc_type: Yup.string(),
    postcode: Yup.string(),
  })
  .required()

export type AccountFormData = Yup.InferType<typeof AccountSchema>
