import React, {ChangeEvent, FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ChatBubbleIcon, Heading, MainLayout, TrophyIcon} from 'src/components'
import {toFormData} from 'src/helpers'
import {PreviousReferrals} from './PreviousReferrals'
import {
  getReferredUsers,
  selectReferredUsersLoading,
  selectReferredUsers,
  referUser,
} from './referralsSlice'

export const ReferralsPage: FC = () => {
  const dispatch = useDispatch()
  const [fullNameInput, setFullNameInput] = useState('')
  const handleFullNameChange = ({target}: ChangeEvent<HTMLInputElement>) =>
    setFullNameInput(target.value)
  const [emailInput, setEmailInput] = useState('')
  const handleEmailChange = ({target}: ChangeEvent<HTMLInputElement>) =>
    setEmailInput(target.value)

  const referredUsers = useSelector(selectReferredUsers)
  const isLoading = useSelector(selectReferredUsersLoading)

  const handleSubmit = () => {
    dispatch(referUser(toFormData({email: emailInput, name: fullNameInput})))
  }

  useEffect(() => {
    dispatch(getReferredUsers())
  }, [dispatch])

  return (
    <MainLayout>
      <section className="flex justify-between">
        <Heading text="Referrals" />
      </section>
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-6">
        <section className="flex-1 flex flex-col">
          <section className="flex">
            <div className="flex-1">
              <ChatBubbleIcon
                strokeColor="text-red-600"
                strokeWidth={30}
                width={40}
                height={40}
              />
              <p className="font-bold text-lg pt-2 leading-snug">Step 1</p>
              <p className="leading-snug">Refer a friend</p>
            </div>
            <div className="flex-1">
              <TrophyIcon
                strokeColor="text-red-600"
                strokeWidth={30}
                width={40}
                height={40}
              />
              <p className="font-bold text-lg pt-2 leading-snug">Step 2</p>
              <p className="leading-snug">Earn rewards</p>
            </div>
          </section>
          <p className="pt-8 text-sm text-gray-700 tracking-wide">
            Invite a friend today. The more friends you invite the more chances
            you have to unlock additional opportunities to reserve additonal
            rewards.
          </p>
        </section>
        <section className="flex-1">
          <form className="max-w-sm mx-auto">
            <input
              required
              onChange={handleFullNameChange}
              type="text"
              value={fullNameInput}
              name="fullName"
              placeholder="Friend's name"
              className="appearance-none text-black font-semibold bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-2"
            />
            <input
              required
              onChange={handleEmailChange}
              type="text"
              value={emailInput}
              name="email"
              placeholder="Friend's email address"
              className="appearance-none text-black font-semibold bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-2"
            />
            <button
              type="button"
              onClick={handleSubmit}
              className={`py-4 px-6 w-full rounded-md border font-medium ${
                fullNameInput.length > 1 && emailInput.length > 1
                  ? 'bg-red-600 border-red-600 text-white'
                  : 'border-gray-400 text-gray-400'
              }`}
            >
              Refer
            </button>
          </form>
        </section>
        <section className="md:col-span-2 lg:col-span-1">
          <div>
            <p className="leading-5 pt-12 md:pt-8 lg:pt-0 pb-2 font-semibold text-gray-700">
              Previous Referrals
            </p>
          </div>
          <PreviousReferrals loading={isLoading} referrals={referredUsers} />
        </section>
      </section>
    </MainLayout>
  )
}
