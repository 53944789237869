import React, {FC, Fragment, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Heading, LoadingSpinner, MainLayout} from 'src/components'
import {Faq} from './FAQ'
import {selectCurrentFaq, selectFaqLoading, selectFaqs} from './faqSelectors'
import {getCurrentFaq, getFaqs} from './faqsSlice'

export const FAQPage: FC = () => {
  const dispatch = useDispatch()
  const faqs = useSelector(selectFaqs)
  const faqIsLoading = useSelector(selectFaqLoading)
  const answerIsLoading = useSelector(selectFaqLoading)
  const answer = useSelector(selectCurrentFaq)
  const [isActiveFaq, setIsActiveFaq] = useState(0)
  useEffect(() => {
    dispatch(getFaqs())
  }, [dispatch])

  const handleFaqClick = (id: number) => {
    setIsActiveFaq(id)
    dispatch(getCurrentFaq(id.toString()))
  }

  return (
    <MainLayout>
      <Heading text="FAQs & Help" />
      {faqIsLoading ? (
        <div className="mt-6">
          <LoadingSpinner />
        </div>
      ) : (
        faqs.map(faq => (
          <Fragment key={faq.id}>
            <Faq
              answerIsLoading={answerIsLoading}
              isActive={isActiveFaq === faq.id}
              answer={answer?.description ?? ''}
              faq={faq}
              handleClick={handleFaqClick}
            />
          </Fragment>
        ))
      )}
    </MainLayout>
  )
}
