import React, {FC, useEffect, useCallback, useState} from 'react'
import {HorizontalScroll, TrendingUpIcon, MainLayout} from 'src/components'
import {useSelector, useDispatch} from 'react-redux'
import {range, placeholderImage, currencyFormatter} from 'src/helpers'
import {selectAuthProfile} from '../account/accountSelector'
import {fetchAuthUser} from '../account'
import {UserBadge} from '../../components/ui'
import {
  getOpportunities,
  selectOpportunities,
  selectOpportunitiesLoading,
  selectTotalOpportunities,
} from '../opportunities/opportunitySlice'
import {getRewardsData, selectRewardsData} from '../rewards'
import {BondCard, SkeletonBondCard} from './BondCard'
import {InvestmentCard, SkeletonInvestmentCard} from './InvestmentCard'
import {TopMembersCard, SkeletonTopMembersCard} from './TopMembers'
import {
  getUserBonds,
  selectUserBonds,
  selectUserBondsLoading,
  selectTopInvestors,
  getTopInvestors,
  selectTopInvestorsLoading,
} from './dashboardSlice'
import {ReferralRewards} from './ReferralRewards'

// eslint-disable-next-line max-lines-per-function
export const DashboardPage: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAuthUser())
    dispatch(getUserBonds())
    dispatch(getTopInvestors())
    dispatch(getOpportunities(currentPage))
    dispatch(getRewardsData())
  }, [dispatch, currentPage])
  const account = useSelector(selectAuthProfile)
  const bonds = useSelector(selectUserBonds)
  const investors = useSelector(selectTopInvestors)
  const investmentOverview = useSelector(selectRewardsData)
  const totalOpportunities = useSelector(selectTotalOpportunities)
  const opportunities = useSelector(selectOpportunities)
  const opportunitiesLoading = useSelector(selectOpportunitiesLoading)
  const bondsLoading = useSelector(selectUserBondsLoading)
  const investorsLoading = useSelector(selectTopInvestorsLoading)
  const hasMore = opportunities && totalOpportunities > opportunities.length

  const loadMoreOpportunities = () => {
    if (hasMore) {
      setCurrentPage(currentPage + 1)
    }
  }

  const renderBondsList = useCallback(() => {
    if (bondsLoading && (!bonds || !bonds.length))
      return range(0, 3).map(val => <SkeletonBondCard key={val} />)
    if (!bonds || !bonds.length)
      return (
        <div className="flex justify-center">
          <p>No bonds</p>
        </div>
      )
    return bonds.map(bond => <BondCard key={bond.id} bond={bond} />)
  }, [bonds, bondsLoading])
  const renderInvestorsList = useCallback(() => {
    if (investorsLoading && (!investors || !investors.length))
      return range(0, 3).map(val => <SkeletonTopMembersCard key={val} />)
    if (!investors || !investors.length)
      return (
        <div className="flex justify-center">
          <p>No investors</p>
        </div>
      )
    return investors.map(investor => (
      <TopMembersCard key={investor.id} investor={investor} />
    ))
  }, [investors, investorsLoading])
  const renderOpportunitiesList = useCallback(() => {
    if (opportunitiesLoading && (!opportunities || !opportunities.length))
      return range(0, 3).map(val => <SkeletonInvestmentCard key={val} />)
    if (!opportunities || !opportunities.length)
      return (
        <div className="flex justify-center">
          <p>No opportunities</p>
        </div>
      )
    return opportunities.map(opportunity => (
      <InvestmentCard key={opportunity.id} opportunity={opportunity} />
    ))
  }, [opportunities, opportunitiesLoading])
  return (
    <MainLayout>
      <section className="flex-shrink-0 flex flex-wrap group  focus:outline-none">
        <UserBadge
          image={`${
            account?.profile_pic
              ? `data:image/png;base64,${account.profile_pic}`
              : placeholderImage
          }`}
          level={account?.level ?? ''}
          name={`${account ? `${account.firstname} ${account.lastname}` : ''}`}
        />
        <div className="flex flex-col order-3 md:order-2 w-full md:w-1/2 lg:w-1/3">
          <div className="md:ml-6">
            <p className="leading-5 pb-2 font-semibold text-red-600">
              Investment Overview
            </p>
          </div>
          <div className="flex md:ml-6 justify-between p-4 bg-gray-100 rounded-lg shadow">
            <div>
              <p className="text-xs font-light">Total invested</p>
              <p className="font-bold text-xl">
                {currencyFormatter.format(
                  investmentOverview?.total_invested || 0,
                )}
              </p>
            </div>
            <div>
              <p className="text-xs font-light">Returns to date</p>
              <p className="font-bold text-xl">
                {currencyFormatter.format(
                  investmentOverview?.returns_to_date || 0,
                )}
              </p>
            </div>
            <div>
              <TrendingUpIcon
                fillColor="#D50F10"
                strokeColor="text-red-600"
                className="w-10 h-10"
              />
            </div>
          </div>
        </div>
        <ReferralRewards
          level={account?.level || ''}
          returns={investmentOverview?.reward || 0}
        />
        <div className="flex flex-col order-2 md:order-4 max-w-full">
          <p className="leading-5 pt-4 font-semibold text-black ">Bonds</p>
          <HorizontalScroll>{renderBondsList()}</HorizontalScroll>
        </div>
        <div className="flex flex-col order-last pt-4 md:pt-0 w-full lg:pl-6 lg:w-1/3">
          <div>
            <p className="leading-5 pb-2 font-semibold text-gray-900">
              Top Members
            </p>
          </div>
          <div className="lg:mr-6 bg-gray-100 shadow overflow-hidden rounded-md">
            <ul className="divide-y divide-white">{renderInvestorsList()}</ul>
          </div>
        </div>
        <div className="flex flex-col order-last md:order-5 pt-4 md:pt-0 w-full  lg:w-2/3">
          <p className="leading-5 font-semibold text-black ">Opportunities</p>
          <HorizontalScroll>{renderOpportunitiesList()}</HorizontalScroll>
          {hasMore && (
            <button
              className="bg-red-600 rounded-md text-white px-4 py-2 my-2 w-1/4 self-end"
              onClick={loadMoreOpportunities}
            >
              More &rarr;
            </button>
          )}
        </div>
      </section>
    </MainLayout>
  )
}
