import React, {FC} from 'react'
import {StarIcon} from 'src/components'
import {ShieldIcon} from '../svg'

type Props = {
  image: string
  name: string
  level: string
}

export enum BadgeLevels {
  'FUND HOLDER' = 'bg-black',
  'GREEN Associate' = 'bg-green-400',
  'SILVER Associate' = 'bg-gray-400',
  'GOLD Associate' = 'bg-yellow-600',
  'PLATINUM Associate' = 'bg-gray-600',
  'PLATINUM Plus Associate' = 'bg-gray-600',
  'AMBASSADOR' = 'bg-red-600',
}

export const UserBadge: FC<Props> = ({image, level, name}) => {
  return (
    <div className="flex items-center justify-between w-full md:w-1/2 lg:w-1/3">
      <div className={`mr-3 ${name ? '' : 'w-5/6'}`}>
        {name ? (
          <p className="text-2xl lg:text-3xl leading-5 pb-2 font-semibold group-hover:text-gray-900">
            {name}
          </p>
        ) : (
          <div className="h-4 bg-gray-400 mb-2 rounded w-3/4" />
        )}
        {level ? (
          <p
            className={`${BadgeLevels[level]} text-xs capitalize lg:text-base py-1 text-center rounded-full leading-4 font-light text-white transition ease-in-out duration-150`}
          >
            {level}
          </p>
        ) : (
          <div className="h-4 bg-gray-400 rounded w-3/4" />
        )}
      </div>
      <span className="inline-block relative">
        <img
          className="h-16 w-16 lg:h-20 lg:w-20 rounded-full"
          src={image}
          alt=""
        />
        <span
          className={`${
            level === 'AMBASSADOR' ? '' : BadgeLevels[level]
          } absolute bottom-0 left-0 h-6 w-6 lg:h-8 lg:w-8 rounded-full inline-flex items-center justify-center text-white`}
        >
          {level === 'AMBASSADOR' ? (
            <ShieldIcon width={60} height={60} fillColor="#D50F10" />
          ) : (
            <StarIcon
              strokeColor="current-color"
              fillColor={level ? '#fff' : 'none'}
            />
          )}
        </span>
      </span>
    </div>
  )
}
