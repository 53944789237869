import React, {FC, useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Logo from 'src/assets/images/logo.png'
import StripeCheckout, {Token} from 'react-stripe-checkout'
import {currencyFormatter} from 'src/helpers'
import {selectAuthProfile} from '../account/accountSelector'
import {payForOpportunity} from './opportunitySlice'

type Props = {
  closeForm: () => void
  unitPrice: number
  unitRemaining: number
  investmentName: string
  investmentImage: string
  bondId: string
}
// eslint-disable-next-line max-lines-per-function
export const InvestmentForm: FC<Props> = ({
  closeForm,
  unitPrice,
  unitRemaining,
  investmentName,
  // investmentImage,
  bondId,
}) => {
  const dispatch = useDispatch()
  const [unitsInput, setUnitsInput] = useState('')
  const [amountInput, setAmountInput] = useState('')
  const [riskTerms, setRiskTerms] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const handleUnitsChange = ({target}: ChangeEvent<HTMLInputElement>) => {
    if (target.value === '') {
      setUnitsInput('')
      setAmountInput('')
    }
    if (
      parseInt(target.value, 10) &&
      parseInt(target.value, 10) <= unitRemaining
    ) {
      setUnitsInput(target.value)
      setAmountInput(`${parseInt(target.value, 10) * unitPrice}`)
    }
  }
  const handleSubmit = (stripeToken: Token) => {
    const paymentInfo = {
      bond_id: bondId,
      unit_bought: unitsInput,
      unit_price: amountInput,
      price_with_fee: 0,
      refrence: stripeToken.id,
    }
    dispatch(payForOpportunity(paymentInfo))
  }

  const handleAmountChange = ({target}: ChangeEvent<HTMLInputElement>) => {
    if (target.value === '') {
      setUnitsInput('')
      setAmountInput('')
    }
    if (
      parseInt(target.value, 10) &&
      parseInt(target.value, 10) / unitPrice <= unitRemaining
    ) {
      setAmountInput(target.value)
      setUnitsInput(`${parseInt(target.value, 10) / unitPrice}`)
    }
  }

  const handleRiskTermsChange = () => setRiskTerms(prev => !prev)
  const handlePrivacyPolicyChange = () => setPrivacyPolicy(prev => !prev)
  const user = useSelector(selectAuthProfile)
  const formIsNotComplete = !(
    unitsInput.length &&
    amountInput.length &&
    riskTerms &&
    privacyPolicy
  )
  console.log(user)
  return (
    <form className="px-3">
      <div>
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="units"
            type="text"
            required
            value={unitsInput}
            name="units"
            onChange={handleUnitsChange}
            placeholder="Enter units (Min. 2 rounded to nearest)"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>

      <div className="mt-3">
        <div className="mt-1 rounded-md shadow-sm">
          <input
            id="amount"
            type="text"
            required
            value={amountInput}
            name="amount"
            onChange={handleAmountChange}
            placeholder="Enter amount"
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
          />
        </div>
      </div>
      <div className="pt-3 pb-5">
        <div className="-mt-px text-sm leading-snug font-bold grid grid-cols-2 grid-rows-1">
          <p className="pr-1 font-light text-gray-900">Transaction fee</p>
          <p className="px-1 font-light text-gray-900">Transaction total</p>
        </div>
        <div className="-mt-px leading-5 font-bold grid grid-cols-2 grid-rows-1">
          <p className="pr-1">
            {currencyFormatter.format(
              unitsInput ? parseInt(unitsInput, 10) * 20 : 0,
            )}
          </p>
          <p className="px-1">
            {currencyFormatter.format(
              amountInput
                ? parseInt(amountInput, 10) + parseInt(unitsInput, 10) * 20
                : 0,
            )}
          </p>
        </div>
      </div>
      <div>
        <div className="flex items-center">
          <input
            id="riskTerms"
            type="checkbox"
            checked={riskTerms}
            name="riskTerms"
            onChange={handleRiskTermsChange}
            className="form-checkbox text-red-600 transition duration-150 ease-in-out"
          />
          <label
            htmlFor="riskTerms"
            className="pl-4 block text-xs md:text-sm leading-5 text-gray-900"
          >
            Yes, I have read the risk terms
          </label>
        </div>
        <div className="flex pt-2 items-center">
          <input
            id="privacyPolicy"
            type="checkbox"
            checked={privacyPolicy}
            name="privacyPolicy"
            onChange={handlePrivacyPolicyChange}
            className="form-checkbox text-red-600 transition duration-150 ease-in-out"
          />
          <label
            htmlFor="privacyPolicy"
            className="pl-4 block text-xs md:text-sm leading-5 text-gray-900"
          >
            Yes, I agree to the terms and conditions and that my personal data
            will be processed in accordance with SOAR privacy policy and
            I&apos;m happy for you to disclose my personal data to SOARising and
            its group
          </label>
        </div>
      </div>
      <div className="grid grid-cols-2 col-gap-2 py-4">
        <span className="inline-flex w-full rounded-md shadow-sm">
          <button
            type="button"
            onClick={closeForm}
            className="py-2 px-4 w-full border border-gray-300 rounded-md text-sm leading-5 font-medium text-white bg-gray-400 focus:outline-none focus:border-red-300 focus:shadow-outline-red active:bg-gray-50 transition duration-150 ease-in-out"
          >
            Cancel
          </button>
        </span>
        <StripeCheckout
          name="Soarising.com"
          email={user?.email || ''}
          image={Logo}
          allowRememberMe={false}
          description={`${currencyFormatter.format(
            amountInput
              ? parseInt(amountInput, 10) + parseInt(unitsInput, 10) * 20
              : 0,
          )} for ${unitsInput} units of ${investmentName}`}
          amount={
            (amountInput
              ? parseInt(amountInput, 10) + parseInt(unitsInput, 10) * 20
              : 0) * 100
          } // cents
          token={token => handleSubmit(token)}
          stripeKey={
            process.env.REACT_APP_STRIPE_KEY ||
            'pk_test_51GzNK4LDmeiQqlVwDWZPWXaRyAWrTjgwpqcrjcy44xfVaXON55j2qCdtiqEkCSRkxIGxMDM34Cd8akUy5A3DujQk00dbZoznce'
          }
        >
          <span className="w-full inline-flex rounded-md shadow-sm">
            <button
              disabled={formIsNotComplete}
              type="button"
              className={`inline-flex justify-center py-2 px-4 w-full border border-xtransparent text-sm leading-5 font-medium rounded-md transition duration-150 ease-in-out${
                formIsNotComplete
                  ? 'border-gray-400 text-gray-400'
                  : ' bg-red-600 border-red-600 text-white'
              }`}
            >
              Save
            </button>
          </span>
        </StripeCheckout>
      </div>
    </form>
  )
}
