import {
  updateUserIdentification,
  updateUserInvestmentType,
  updateUserProfilePicture,
} from 'src/api/authAPI'
import {AppThunk} from 'src/app/store'
import {
  updateIdentificationFailure,
  updateIdentificationStart,
  updateIdentificationSuccess,
  updateInvestmentTypeStart,
  updateInvestmentTypeSuccess,
  updateInvestmentTypeFailure,
  updateProfilePictureStart,
  updateProfilePictureSuccess,
  updateProfilePictureFailure,
} from '../login'

export const updateIdentification = (
  data: FormData,
): AppThunk<Promise<boolean>> => async dispatch => {
  try {
    dispatch(updateIdentificationStart())
    await updateUserIdentification(data)
    dispatch(updateIdentificationSuccess())
  } catch (err) {
    dispatch(updateIdentificationFailure(err))
    return false
  }
  return true
}

export const updateInvestmentType = (
  data: FormData,
): AppThunk<Promise<boolean>> => async dispatch => {
  try {
    dispatch(updateInvestmentTypeStart())
    await updateUserInvestmentType(data)
    dispatch(updateInvestmentTypeSuccess())
  } catch (err) {
    dispatch(updateInvestmentTypeFailure(err))
    return false
  }
  return true
}

export const updateProfilePicture = (
  data: FormData,
): AppThunk<Promise<boolean>> => async dispatch => {
  try {
    dispatch(updateProfilePictureStart())
    await updateUserProfilePicture(data)
    dispatch(updateProfilePictureSuccess())
  } catch (err) {
    dispatch(updateProfilePictureFailure(err))
    return false
  }
  return true
}
