import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import {
  fetchOpportunities,
  fetchOpportunity,
  makePayment,
  Opportunities,
  OpportunitiesMeta,
  Opportunity,
  PaymentInfo,
} from 'src/api/bondsAPI'
import {AppThunk, RootState} from 'src/app/store'

const getUserOpportunitiesState = (state: RootState) => state.userOpportunities

export const selectTotalOpportunities = createSelector(
  getUserOpportunitiesState,
  opportunities => opportunities.totalOpportunities,
)

export const selectOpportunities = createSelector(
  getUserOpportunitiesState,
  opportunities => opportunities.data,
)

export const selectOpportunity = createSelector(
  getUserOpportunitiesState,
  opportunities => opportunities.opportunity,
)

export const selectOpportunitiesLoading = createSelector(
  getUserOpportunitiesState,
  opportunities => opportunities.loading,
)

interface UserOpportunitiesState {
  data: Opportunities[] | undefined
  totalOpportunities: number
  pages: number
  opportunity: Opportunity | undefined
  loading: boolean
  error: string | null
}

const initialState: UserOpportunitiesState = {
  totalOpportunities: 0,
  pages: 0,
  data: undefined,
  opportunity: undefined,
  loading: true,
  error: null,
}

const userOpportunities = createSlice({
  name: 'userOpportunities',
  initialState,
  reducers: {
    getOpportunityStart(state) {
      return {
        ...state,
        loading: true,
        error: null,
      }
    },
    getOpportunitySuccess(state, action: PayloadAction<Opportunity>) {
      return {
        ...state,
        opportunity: action.payload,
        loading: false,
        error: null,
      }
    },
    getOpportunityFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    },
    clearOpportunity(state, action: PayloadAction<undefined>) {
      return {
        ...state,
        opportunity: action.payload,
        loading: false,
        error: null,
      }
    },
    getOpportunitiesStart(state) {
      return {
        ...state,
        loading: true,
        error: null,
      }
    },
    getOpportunitiesSuccess(state, action: PayloadAction<OpportunitiesMeta>) {
      return {
        ...state,
        data: state.data?.concat(action.payload.data) || action.payload.data,
        pages: action.payload.pages,
        totalOpportunities: action.payload.total_bond,
        loading: false,
        error: null,
      }
    },
    getOpportunitiesFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    },
    payForOpportunityStart(state) {
      return {
        ...state,
        loading: true,
        error: null,
      }
    },
    payForOpportunitySuccess(state) {
      return {
        ...state,
        loading: false,
        error: null,
      }
    },
    payForOpportunityFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    },
  },
})

export const {
  getOpportunityStart,
  getOpportunitySuccess,
  getOpportunityFailure,
  getOpportunitiesStart,
  getOpportunitiesSuccess,
  getOpportunitiesFailure,
  clearOpportunity,
  payForOpportunityStart,
  payForOpportunitySuccess,
  payForOpportunityFailure,
} = userOpportunities.actions
export default userOpportunities.reducer

export const getOpportunity = (id: string): AppThunk => async dispatch => {
  try {
    dispatch(getOpportunityStart())
    const opportunity = await fetchOpportunity(id)
    dispatch(getOpportunitySuccess(opportunity))
  } catch (err) {
    dispatch(getOpportunityFailure(err))
  }
}

export const getOpportunities = (
  pageNum: number,
): AppThunk => async dispatch => {
  try {
    dispatch(getOpportunitiesStart())
    const data = await fetchOpportunities(pageNum)
    console.log('data', data)
    dispatch(getOpportunitiesSuccess(data))
  } catch (err) {
    dispatch(getOpportunitiesFailure(err))
    console.log(err)
  }
}

export const payForOpportunity = (
  paymentInfo: PaymentInfo,
): AppThunk => async dispatch => {
  try {
    dispatch(payForOpportunityStart())
    const response = await makePayment(paymentInfo)
    console.log('opport', response)
    dispatch(payForOpportunitySuccess())
    toast.success(`Congratulations! ${response.message}.`)
  } catch (err) {
    dispatch(payForOpportunityFailure(err))
    console.log(err)
  }
}
