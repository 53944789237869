import React, {FC} from 'react'
import Page404 from 'src/assets/images/page_not_found.png'
import Page404Webp from 'src/assets/images/page_not_found.webp'

const PageNotFound: FC = () => {
  return (
    <main className="flex flex-col justify-center items-center h-screen">
      <div className="mx-auto w-1/2">
        <picture className="text-center">
          <source srcSet={Page404Webp} type="image/webp" />
          <source srcSet={Page404} type="image/png" />
          <img src={Page404} alt="Page not found" className="mx-auto" />
        </picture>
      </div>
    </main>
  )
}

export default PageNotFound
