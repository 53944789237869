import axiosInstance from './axiosInstance'

export interface User {
  login: string
  avatar_url: string
}

export type AuthUser = {
  firstname: string
  lastname: string
  id: number
  level: string
  email: string
  // eslint-disable-next-line no-warning-comments
  // FIXME: fix identifcation spelling
  indetification: {
    name: string
    number: string
  }
  address: {
    address_line: string
    city: string
    postcode: string
    country: string
  }
  currency: string
  currency_symbol: string
  currency_list: Array<string>
  country_list: Array<string>
  doc_list: Array<string>
  date_of_birth: string
  agreement_link: string
  doc_image: string
  profile_pic: string
}
interface updateAuthUserResponse {
  data: {
    message: string
  }
}

export async function loginUser(
  credentials: FormData,
): Promise<{
  token: string
  welcome: number
}> {
  const {data} = await axiosInstance.post<{token: string; welcome: number}>(
    '/api-aut-login/',
    credentials,
    {
      headers: {'Content-Type': 'multipart/form-data'},
    },
  )
  return data
}

export async function updateUserIdentification(
  formData: FormData,
): Promise<{
  message: string
}> {
  const {data} = await axiosInstance.post<{message: string}>(
    '/api/update-identification',
    formData,
  )
  return data
}

export async function updateUserInvestmentType(
  formData: FormData,
): Promise<{
  message: string
}> {
  const {data} = await axiosInstance.post<{message: string}>(
    '/api/update-certificate',
    formData,
  )
  return data
}

export async function updateUserProfilePicture(
  formData: FormData,
): Promise<{
  message: string
}> {
  const {data} = await axiosInstance.post<{message: string}>(
    '/api/update-profile-pic',
    formData,
  )
  return data
}

export async function getUserStatus(
  token: string,
): Promise<{
  identification_status: number
  certificate_checkbox: number
  certificate_uplaod: number
  investor_type: number
  profile_update: number
}> {
  const {data} = await axiosInstance.get<{
    identification_status: number
    certificate_checkbox: number
    certificate_uplaod: number
    investor_type: number
    profile_update: number
  }>('/api/user/status', {
    headers: {
      Authorization: `Token ${token}`,
    },
  })
  return data
}

export async function fetchUser(): Promise<AuthUser> {
  const {data} = await axiosInstance.get<AuthUser>('/api/user')
  return data
}

export async function updateUser(
  accountData: FormData,
): Promise<updateAuthUserResponse> {
  const {data} = await axiosInstance.post<updateAuthUserResponse>(
    '/api/update-profile',
    accountData,
  )
  return data
}

export async function deleteUser(): Promise<unknown> {
  const {data} = await axiosInstance.post<AuthUser>('/api/delete-acount')
  return data
}
