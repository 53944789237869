import React from 'react'
import {SvgIcon} from './SvgIcon'

export const ShieldIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  strokeWidth = 20,
  fillColor = 'none',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className={`${strokeColor} ${className}`}
  >
    <rect fill="#fff" x="6" y="6" width="8" height="8" />
    <path
      d="M17.604 3.332c-4.614 0.668-5.529-0.499-7.604-2.332-2.075 1.833-2.99 3-7.604 2.332-2.459 12.248 7.604 15.668 7.604 15.668s10.063-3.42 7.604-15.668zM12.473 13.309l-2.473-1.3-2.472 1.3 0.472-2.753-2-1.95 2.764-0.401 1.236-2.505 1.236 2.505 2.764 0.401-2 1.949 0.473 2.754z"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
