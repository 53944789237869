/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, {FC, Fragment} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import {AuthUser} from 'src/api/authAPI'
import {UserBadge} from 'src/components/ui'
import {CameraIcon, EditIcon, InputField} from 'src/components'
import {compatibleDate, toFormData} from 'src/helpers'
import useEditAccount from './useEditAccount'
import {AccountFormData} from './types'

export const EditAccountForm: FC<{
  account?: AuthUser
  submitForm: (data: FormData) => void
}> = ({account, submitForm}) => {
  const {
    dob,
    isDirty,
    errors,
    formState,
    handleDateChange,
    handleEditField,
    handleIdImage,
    handleImageUpdate,
    handleSubmit,
    hasNewID,
    image,
    idImage,
    register,
    setIsDirty,
    verifyEditables,
  } = useEditAccount(account)

  const onSubmit = (data: AccountFormData) => {
    const formData = {
      ...data,
      date_of_birth: compatibleDate(dob),
      doc_image: idImage,
      profile_pic: image,
    }
    submitForm(toFormData(formData))
    setIsDirty(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <UserBadge
        image={`${image}`}
        level={account?.level ?? ''}
        name={`${account?.firstname} ${account?.lastname}`}
      />
      <div className="flex justify-between my-2">
        <span>#123455</span>
        <span className="flex items-end">
          Edit photo
          <label id="picture">
            <EditIcon
              className="mx-2"
              fillColor="currentColor"
              strokeColor="text-gray-500"
            />
            <input
              onChange={handleImageUpdate}
              id="profile_pic"
              name="profile_pic"
              accept="image/*"
              type="file"
              className="hidden"
            />
          </label>
        </span>
      </div>
      <section>
        <div className="flex items-baseline justify-between">
          <p className="leading-5 pt-8 pb-2 font-semibold text-gray-700">
            Login
          </p>
          <button
            className="cursor-pointer"
            type="button"
            name="login"
            onClick={handleEditField}
          >
            <EditIcon
              className="mx-2"
              fillColor="currentColor"
              strokeColor="text-gray-500"
            />
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:col-gap-4">
          <InputField
            readOnly
            value={account?.email}
            name="email"
            type="email"
          />
          <InputField readOnly name="password" type="password" value="****" />
        </div>
      </section>
      <section>
        <div className="flex items-baseline justify-between">
          <p className="leading-5 pt-8 pb-2 font-semibold text-gray-700">
            Identification
          </p>
          <button
            className="cursor-pointer"
            type="button"
            name="identification"
            onClick={handleEditField}
          >
            <EditIcon
              className="mx-2"
              fillColor="currentColor"
              strokeColor="text-gray-500"
            />
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:col-gap-4">
          <div>
            <div className="flex items-center px-2 my-1 rounded-lg bg-gray-100">
              <span className="mr-2 flex-shrink-0">
                <select
                  disabled={verifyEditables('identification')}
                  ref={register}
                  className="my-2 appearance-none w-full px-4 rounded-lg bg-gray-100 placeholder-gray-400"
                  name="doc_type"
                >
                  {account?.doc_list.map(item => (
                    <Fragment key={item}>
                      <option
                        value={item.trim().replace(' ', '').toLowerCase()}
                      >
                        {item}
                      </option>
                    </Fragment>
                  ))}
                </select>
              </span>
              <InputField
                className="py-3"
                readOnly={verifyEditables('identification')}
                ref={register}
                name="doc_number"
              />
            </div>
            <p
              className={`${
                errors?.doc_type?.['message'] ? 'inline' : 'hidden'
              } pl-2 text-sm  text-red-600`}
            >
              {errors?.doc_type?.['message'] || ''}
            </p>
          </div>
          <div className="flex items-center px-2 my-1 rounded-lg bg-gray-100 overflow-hidden">
            <span className="mr-2 flex-shrink-0 p-4">Date of Birth</span>
            <DatePicker
              ref={register}
              name="date_of_birth"
              className="bg-gray-100 "
              readOnly={verifyEditables('identification')}
              selected={dob}
              onChange={handleDateChange}
            />
          </div>
        </div>
        {idImage ? (
          <div className="max-w-sm rounded mb-4 overflow-hidden shadow-lg">
            <img className="w-full" src={idImage as string} alt="" />
          </div>
        ) : null}
        {hasNewID && (
          <>
            <label
              htmlFor="doc_image"
              className="underline text-gray-600 inline-flex items-center cursor-pointer justify-center p-4 border border-transparent text-sm font-medium rounded-md "
            >
              <span>
                <CameraIcon
                  width={18}
                  height={18}
                  fillColor="currentColor"
                  className="mr-2 text-gray-600"
                />
              </span>
              Upload ID photo
            </label>
            <input
              onChange={handleIdImage}
              id="doc_image"
              name="doc_image"
              type="file"
              accept="image/*"
              className="hidden"
            />
            <p
              className={`${
                errors?.doc_image?.message ? 'inline' : 'hidden'
              } pl-2 text-sm  text-red-600`}
            >
              {errors?.doc_image?.message || ''}
            </p>
          </>
        )}
      </section>
      <section>
        <div className="flex items-baseline justify-between">
          <p className="leading-5 pt-8 pb-2 font-semibold text-gray-700">
            Currency
          </p>
          <button
            className="cursor-pointer"
            type="button"
            name="currency"
            onClick={handleEditField}
          >
            <EditIcon
              className="mx-2"
              fillColor="currentColor"
              strokeColor="text-gray-500"
            />
          </button>
        </div>
        <div className="grid grid-cols-1">
          <div className="flex items-center px-2 my-1 rounded-lg bg-gray-100">
            <select
              disabled={verifyEditables('currency')}
              ref={register}
              className="my-2 appearance-none w-full px-4 py-2 rounded-lg font-light bg-gray-100 placeholder-gray-400"
              name="currency"
            >
              {account?.currency_list.map(item => (
                <Fragment key={item}>
                  <option value={item}>{item}</option>
                </Fragment>
              ))}
            </select>
          </div>
          <p
            className={`${
              errors?.currency?.message ? 'inline' : 'hidden'
            } pl-2 text-sm text-red-600`}
          >
            {errors?.currency?.message || ''}
          </p>
        </div>
      </section>
      <section>
        <div className="flex items-baseline justify-between">
          <p className="leading-5 pt-8 pb-2 font-semibold text-gray-700">
            Address Information
          </p>
          <button
            className="cursor-pointer"
            type="button"
            name="address"
            onClick={handleEditField}
          >
            <EditIcon
              className="mx-2"
              fillColor="currentColor"
              strokeColor="text-gray-500"
            />
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 md:col-gap-4">
          <div>
            <InputField
              readOnly={verifyEditables('address')}
              ref={register}
              name="address_line"
              placeholder="12 Armstrong street"
            />
            <p
              className={`${
                errors?.address_line?.message ? 'inline' : 'hidden'
              } pl-2 text-sm text-red-600`}
            >
              {errors?.address_line?.message || ''}
            </p>
          </div>
          <div>
            <InputField
              readOnly={verifyEditables('address')}
              ref={register}
              name="city"
              placeholder="London"
            />
            <p
              className={`${
                errors?.city?.message ? 'inline' : 'hidden'
              } pl-2 text-sm text-red-600`}
            >
              {errors?.city?.message || ''}
            </p>
          </div>
          <div>
            <InputField
              readOnly={verifyEditables('address')}
              ref={register}
              name="postcode"
              placeholder="NJ189k"
            />
            <p
              className={`${
                errors?.postcode?.message ? 'inline' : 'hidden'
              } pl-2 text-sm text-red-600`}
            >
              {errors?.postcode?.message || ''}
            </p>
          </div>
          <div>
            <InputField
              readOnly={verifyEditables('address')}
              ref={register}
              name="country"
              placeholder="United Kindgom"
            />
            <p
              className={`${
                errors?.country?.['message'] ? 'inline' : 'hidden'
              } pl-2 text-sm  text-red-600`}
            >
              {errors?.country?.['message'] || ''}
            </p>
          </div>
        </div>
      </section>
      {(formState.isDirty || isDirty || hasNewID) && (
        <button
          type="submit"
          className="block text-center py-4 px-6 w-full rounded-md border font-medium 1 border-red-600 text-red-600 my-2"
        >
          Save Changes
        </button>
      )}
    </form>
  )
}
