import React, {FC, useState} from 'react'
import {ChevronRightIcon, CloseIcon, LoadingSpinner} from 'src/components'
import styled from 'styled-components'
import {FAQ} from './types'

const Title = styled.summary.attrs(() => ({
  className:
    'capitalize font-thin py-4 text-gray-700 border-b border-gray-300 flex justify-between',
}))`
  &::-webkit-details-marker {
    display: none;
  }
`

export const Faq: FC<{
  answer: string
  faq: Omit<FAQ, 'description'>
  handleClick: (id: number) => void
  isActive: boolean
  answerIsLoading: boolean
}> = ({answer, answerIsLoading, faq, handleClick, isActive}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      handleClick(faq.id)
    }
  }

  return (
    <section>
      <details>
        <Title onClick={toggleIsOpen}>
          <span>{faq.title}</span>
          <div className="icon items-baseline mx-2">
            {isOpen && isActive ? (
              <CloseIcon width={16} height={16} fillColor="#999" />
            ) : (
              <ChevronRightIcon fillColor="#999" />
            )}
          </div>
        </Title>
        {isActive ? (
          answerIsLoading ? (
            <LoadingSpinner />
          ) : (
            <p className="py-6">{answer}</p>
          )
        ) : null}
      </details>
    </section>
  )
}
