import {createSelector} from '@reduxjs/toolkit'
import {RootState} from '../../app/store'
const getFaqStore = (state: RootState) => state.faq

export const selectFaqLoading = createSelector(
  getFaqStore,
  store => store.loading,
)

export const selectFaqs = createSelector(getFaqStore, store => store.data)

export const selectCurrentFaqLoading = createSelector(
  getFaqStore,
  store => store.currentLoading,
)
export const selectCurrentFaq = createSelector(
  getFaqStore,
  store => store.current,
)
