import React, {ChangeEvent, FC, useState, useEffect} from 'react'
import {Heading, MainLayout} from 'src/components'
import {useDispatch, useSelector} from 'react-redux'
import {currencyFormatter} from 'src/helpers'
import {getRewardsData, selectRewardsData} from './rewardsSlice'

export const RewardsPage: FC = () => {
  const [withdrawalInput, setWithdrawalInput] = useState('')
  const handleWithdrawlInput = ({target}: ChangeEvent<HTMLInputElement>) =>
    setWithdrawalInput(target.value)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getRewardsData())
  }, [dispatch])
  const investmentOverview = useSelector(selectRewardsData)
  return (
    <MainLayout>
      <div>
        <Heading text="Rewards" />
        <div className="flex flex-col justify-center mx-auto w-full lg:w-1/2">
          <div className="text-xl pt-4">
            <p className="leading-5 pb-2 font-semibold text-red-600">
              Wallet Overview
            </p>
          </div>
          <div className="flex justify-between p-4 bg-gray-100 rounded-lg shadow">
            <div>
              <p className="text-xs font-light">Return</p>
              <p className="font-bold text-xl">
                {currencyFormatter.format(investmentOverview?.return || 0)}
              </p>
            </div>
            <div>
              <p className="text-xs font-light">Reward</p>
              <p className="font-bold text-xl">
                {currencyFormatter.format(investmentOverview?.reward || 0)}
              </p>
            </div>
          </div>
          <div className="flex mt-4 justify-between p-4 bg-gray-100 rounded-lg shadow">
            <div>
              <p className="text-xs font-light">Total</p>
              <p className="font-bold text-xl">
                {currencyFormatter.format(
                  investmentOverview
                    ? investmentOverview?.return ??
                        0 + investmentOverview.reward
                    : 0,
                )}
              </p>
            </div>
          </div>
        </div>
        <h4 className="text-base max-w-2xl mx-auto text-center py-4">
          <span className="block font-bold">You&apos;ve been working hard</span>
          <span>Get your reward</span>
        </h4>
        <p className="whitespace-pre-line py-4 max-w-2xl mx-auto mb-4">
          You&apos;ll receive interest repayments yearly from each active bond
          with the principal paid at maturity. By keeping funds on the platform,
          the system will automatically use the available fund in your account
          when you make your next investment or donation.
        </p>
        <div className="max-w-sm mx-auto">
          <small className="text-gray-500 font-light block">
            Available rewards &#36;
            {investmentOverview?.['wallet-balance'] || 0}
          </small>
          <form>
            <input
              onChange={handleWithdrawlInput}
              type="text"
              required
              value={withdrawalInput}
              name="withdrawalInput"
              placeholder="&#36;0"
              className="appearance-none text-black font-semibold bg-gray-100 placeholder-gray-400 w-full p-4 rounded-sm my-2"
            />
            <button
              className={`py-4 px-6 w-full rounded-md border font-medium ${
                withdrawalInput.length < 1
                  ? 'border-gray-400 text-gray-400'
                  : 'bg-red-600 border-red-600 text-white'
              }`}
            >
              Withdraw
            </button>
          </form>
        </div>
      </div>
    </MainLayout>
  )
}
