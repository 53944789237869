import {createGlobalStyle} from 'styled-components'
import styledNormalize from 'styled-normalize'

// eslint-disable-next-line max-lines-per-function
const GlobalStyles = createGlobalStyle`
    ${styledNormalize}

    ::-moz-placeholder {
      color: #9c9b9b;
    }

    ::-webkit-input-placeholder {
      color: #9c9b9b;
    }

    ::selection {
      background: lighten(#D50F10, 25);
    }

    ::-webkit-file-upload-button {
      font: inherit;
      -webkit-appearance: button;
    }

    [tabindex='-1']:focus {
      outline: 0 !important;
    }

  .fontfamily-loaded {
  html,
  body {
    font-family: 'Nunito Sans';
    }
  }
  
  html,
  body {
    font-family: 'Nunito Sans' sans-serif;
  }
    
    a {
      text-decoration: none !important;
      background-color: transparent;
      -webkit-text-decoration-skip: objects;
    }

    a:not([href]):not([tabindex]) {
      color: inherit;
      text-decoration: none;

      &:focus {
        outline: 0;
      }
    }

    img {
      vertical-align: middle;
      border-style: none;
      width: 100%;
    }

    svg:not(:root) {
      overflow: hidden;
    }

    [type='number']::-webkit-inner-spin-button,
    [type='number']::-webkit-outer-spin-button {
      height: auto;
    }

    [type='search'] {
      outline-offset: -2px;
      -webkit-appearance: none;
    }

    [type='search']::-webkit-search-cancel-button,
    [type='search']::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    [hidden] {
      display: none !important;
    }

    img {
      max-width: 100%;
      font-style: italic;
      vertical-align: middle;
    }

    img[width],
    img[height] {
      max-width: none;
    }

    #nprogress {
      pointer-events: none;
    }

    #nprogress .bar {
      background: #D50F10;
      position: fixed;
      z-index: 1031;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      -webkit-animation: shift-rightwards 1s ease-in-out infinite;
      animation: shift-rightwards 1s ease-in-out infinite;
      animation-delay: 0.3s;
    }

    /* Fancy blur effect */

    #nprogress .peg {
      display: block;
      position: absolute;
      right: 0;
      width: 100px;
      height: 100%;
      box-shadow: 0 0 10px #D50F10, 0 0 5px #D50F10;
      opacity: 1;
      -webkit-transform: rotate(3deg) translate(0, -4px);
      -ms-transform: rotate(3deg) translate(0, -4px);
      transform: rotate(3deg) translate(0, -4px);
    }

    #nprogress .spinner {
      display: block;
      position: fixed;
      z-index: 1031;
      top: 15px;
      right: 15px;
    }

    #nprogress .spinner-icon {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: solid 2px transparent;
      border-top-color: #D50F10;
      border-left-color: #D50F10;
      border-radius: 50%;
      -webkit-animation: nprogress-spinner 400ms linear infinite;
      animation: nprogress-spinner 400ms linear infinite;
    }

    .nprogress-custom-parent {
      overflow: hidden;
      position: relative;
    }

    .nprogress-custom-parent #nprogress .spinner,
    .nprogress-custom-parent #nprogress .bar {
      position: absolute;
    }

    @-webkit-keyframes nprogress-spinner {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes nprogress-spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  `

export {GlobalStyles}
