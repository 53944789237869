import React, {FC, useState} from 'react'
import {ChevronDownIcon} from 'src/components'
import {BondHistory} from 'src/api/bondsAPI'
import {currencyFormatter} from 'src/helpers'
import {BondPurchaseHistory} from './BondPurchaseHistory'

export const SkeletonBondDetailsCard: FC = () => {
  return (
    <section className="flex flex-col w-full md:max-w-xs bg-gray-100 rounded-lg shadow">
      <div className="flex-1 flex items-center justify-between p-4">
        <div className="h-4 bg-gray-400 rounded w-3/4" />
        <span className="px-3 py-1 rounded-full w-1/5 text-sm font-medium leading-4 bg-red-600 text-white">
          <div className="h-3 bg-gray-400 rounded w-full" />
        </span>
      </div>
      <div>
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Total Invested</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <span className="relative w-0 flex-1 flex flex-col pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Units purchased</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
        </div>
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Interest rate</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <span className="relative w-0 flex-1 flex flex-col pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Mature date</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
        </div>
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Expected valuation</p>
              <div className="h-4 bg-gray-400 rounded w-3/4" />
            </span>
          </div>
          <div className="flex-1 flex items-center justify-end pr-4">
            <ChevronDownIcon
              strokeColor="text-red-600"
              strokeWidth={50}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export const BondDetailsCard: FC<{bond: BondHistory}> = ({
  bond: {
    name,
    active,
    total_invested,
    unit_purchased,
    interest_rate,
    mature_date,
    expected_valuation,
    history,
  },
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <section className="flex flex-col w-full md:max-w-xs bg-gray-100 rounded-lg shadow">
      <div className="flex items-center justify-between p-4">
        <h3 className="leading-5 uppercase font-bold text-red-600">{name}</h3>
        <span className="px-3 py-1 rounded-full text-sm font-medium leading-4 bg-red-600 text-white">
          {active ? 'active' : 'inactive'}
        </span>
      </div>
      <div className="flex-1">
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Total Invested</p>
              <p>{currencyFormatter.format(total_invested)}</p>
            </span>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <span className="relative w-0 flex-1 flex flex-col pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Units purchased</p>
              <p>{unit_purchased}</p>
            </span>
          </div>
        </div>
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Interest rate</p>
              <p>{interest_rate}%</p>
            </span>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <span className="relative w-0 flex-1 flex flex-col pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Mature date</p>
              <p>{mature_date}</p>
            </span>
          </div>
        </div>
        <div className="-mt-px flex">
          <div className="w-0 flex-1 flex">
            <span className="relative -mr-px w-0 flex-1 flex flex-col pl-4 pb-4 leading-5 font-bold transition ease-in-out duration-150">
              <p className="text-sm font-light">Expected valuation</p>
              <p>{currencyFormatter.format(expected_valuation)}</p>
            </span>
          </div>
          <div
            onClick={handleToggle}
            className="flex-1 flex items-center justify-end pr-4"
          >
            <ChevronDownIcon
              strokeColor="text-red-600"
              strokeWidth={50}
              className="cursor-pointer"
            />
          </div>
        </div>
        {isOpen ? (
          <section className="px-4 pb-4">
            <BondPurchaseHistory bondHistory={history} />
          </section>
        ) : null}
      </div>
    </section>
  )
}
