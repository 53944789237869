import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {TrophyIcon} from 'src/components'
import {currencyFormatter} from 'src/helpers'

const renderReferralStep = (level: string) => {
  switch (level) {
    case 'FUND HOLDER':
      return (
        <p className="text-xs font-light pb-3">
          Refer friends to move up to{' '}
          <span className="font-bold text-xs">Green Associate</span>
        </p>
      )
    case 'GREEN Associate':
      return (
        <p className="text-xs font-light pb-3">
          Refer friends to move up to{' '}
          <span className="font-bold text-xs">Silver Associate</span>
        </p>
      )
    case 'SILVER Associate':
      return (
        <p className="text-xs font-light pb-3">
          Refer friends to move up to{' '}
          <span className="font-bold text-xs">Gold Associate</span>
        </p>
      )
    case 'GOLD Associate':
      return (
        <p className="text-xs font-light pb-3">
          Refer friends to move up to{' '}
          <span className="font-bold text-xs">Platinum Associate</span>
        </p>
      )
    case 'PLATINUM Associate':
      return (
        <p className="text-xs font-light pb-3">
          Refer friends to move up to{' '}
          <span className="font-bold text-xs">Platinum Associate</span>
        </p>
      )
    case 'PLATINUM Plus Associate':
      return (
        <p className="text-xs font-light pb-3">
          Refer friends to move up to{' '}
          <span className="font-bold text-xs">Brand Ambassador</span>
        </p>
      )
    case 'AMBASSADOR':
    default:
      return <p className="text-xs font-light pb-3">Refer friends</p>
  }
}

export const ReferralRewards: FC<{level: string; returns: number}> = ({
  level,
  returns,
}) => {
  return (
    <div className="flex pt-4 lg:pt-0 w-full lg:w-1/3 order-4 md:order-3">
      <div className="grid grid-cols-2 w-full gap-4">
        <div className="lg:ml-6">
          <p className="leading-5 pb-2 font-semibold text-gray-900">
            Referrals
          </p>
          <div className="p-4 bg-gray-100 rounded-lg shadow">
            <div>
              {renderReferralStep(level)}
              <Link to="/referrals">
                <span className="rounded-md shadow-sm">
                  <button
                    type="button"
                    className="w-full flex justify-center p-3 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    Refer
                  </button>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <p className="leading-5 pb-2 font-semibold text-gray-900">
            <span>Rewards</span>{' '}
          </p>
          <div className="p-4 bg-gray-100 rounded-lg shadow">
            <div>
              <div className="flex items-center pb-3 justify-between">
                <div>
                  <TrophyIcon className="w-6 h-6 md:w-4 md:h-4" />
                </div>
                <p className="text-xs text-right font-light">
                  You have{' '}
                  <span className="font-bold text-sm">
                    {currencyFormatter.format(returns)}
                  </span>{' '}
                  available reward
                </p>
              </div>
              <Link to="/referrals">
                <span className="rounded-md shadow-sm">
                  <button
                    type="button"
                    className="w-full flex justify-center p-3 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    Withdraw
                  </button>
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
