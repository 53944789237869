import {createSlice, PayloadAction, createSelector} from '@reduxjs/toolkit'
import {
  fetchUserBonds,
  Bond,
  Investor,
  fetchTopInvestors,
  BondHistory,
  fetchBondsHistory,
} from 'src/api/bondsAPI'
import {AppThunk, RootState} from 'src/app/store'

const getUserBondsState = (state: RootState) => state.userBonds

export const selectUserBonds = createSelector(
  getUserBondsState,
  bonds => bonds.data,
)
export const selectTopInvestors = createSelector(
  getUserBondsState,
  bonds => bonds.investors,
)
export const selectBondsHistory = createSelector(
  getUserBondsState,
  bonds => bonds.history,
)

export const selectUserBondsLoading = createSelector(
  getUserBondsState,
  bonds => bonds.loading,
)

export const selectTopInvestorsLoading = createSelector(
  getUserBondsState,
  bonds => bonds.investorsLoading,
)

export const selectBondsHistoryLoading = createSelector(
  getUserBondsState,
  bonds => bonds.historyLoading,
)

interface UserBondsState {
  data: Bond[] | undefined
  loading: boolean
  error: string | null
  investors: Investor[] | undefined
  investorsLoading: boolean
  investorsError: string | null
  history: BondHistory[] | undefined
  historyLoading: boolean
  historyError: string | null
}

const initialState: UserBondsState = {
  data: undefined,
  loading: true,
  error: null,
  investors: undefined,
  investorsLoading: true,
  investorsError: null,
  history: undefined,
  historyLoading: true,
  historyError: null,
}

const userBonds = createSlice({
  name: 'userBonds',
  initialState,
  reducers: {
    getUserBondsStart(state) {
      return {
        ...state,
        loading: true,
        error: null,
      }
    },
    getUserBondsSuccess(state, action: PayloadAction<Bond[]>) {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      }
    },
    getUserBondsFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    },
    getTopInvestorsStart(state) {
      return {
        ...state,
        investorsLoading: true,
        investorsError: null,
      }
    },
    getTopInvestorsSuccess(state, action: PayloadAction<Investor[]>) {
      return {
        ...state,
        investors: action.payload,
        investorsLoading: false,
        investorsError: null,
      }
    },
    getTopInvestorsFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        investorsLoading: false,
        investorsError: action.payload,
      }
    },
    getBondsHistoryStart(state) {
      return {
        ...state,
        historyLoading: true,
        historyError: null,
      }
    },
    getBondsHistorySuccess(state, action: PayloadAction<BondHistory[]>) {
      return {
        ...state,
        history: action.payload,
        historyLoading: false,
        historyError: null,
      }
    },
    getBondsHistoryFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        historyLoading: false,
        historyError: action.payload,
      }
    },
  },
})

export const {
  getUserBondsStart,
  getUserBondsSuccess,
  getUserBondsFailure,
  getTopInvestorsStart,
  getTopInvestorsSuccess,
  getTopInvestorsFailure,
  getBondsHistoryStart,
  getBondsHistorySuccess,
  getBondsHistoryFailure,
} = userBonds.actions
export default userBonds.reducer

export const getUserBonds = (): AppThunk => async dispatch => {
  try {
    dispatch(getUserBondsStart())
    const bonds = await fetchUserBonds()
    dispatch(getUserBondsSuccess(bonds))
  } catch (err) {
    dispatch(getUserBondsFailure(err))
  }
}

export const getTopInvestors = (): AppThunk => async dispatch => {
  try {
    dispatch(getTopInvestorsStart())
    const investors = await fetchTopInvestors()
    dispatch(getTopInvestorsSuccess(investors))
  } catch (err) {
    dispatch(getTopInvestorsFailure(err))
    console.log(err)
  }
}

export const getBondsHistory = (): AppThunk => async dispatch => {
  try {
    dispatch(getBondsHistoryStart())
    const bondHistory = await fetchBondsHistory()
    dispatch(getBondsHistorySuccess(bondHistory))
  } catch (err) {
    dispatch(getBondsHistoryFailure(err))
    console.log(err)
  }
}
