import {yupResolver} from '@hookform/resolvers'
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {AuthUser} from 'src/api/authAPI'
import {AccountFormData, AccountSchema} from './types'

export default function useEditAccount(account?: AuthUser) {
  const [image, setImage] = useState(
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  )
  const accountData = {
    address_line: account?.address.address_line ?? '',
    city: account?.address.city ?? '',
    country: account?.address.country ?? '',
    currency: account?.currency ?? '',
    date_of_birth: account?.date_of_birth ?? new Date().toDateString(),
    doc_image: account?.doc_image ?? '',
    doc_number: account?.indetification.number ?? '',
    doc_type: account?.indetification.name.replace(' ', '').toLowerCase() ?? '',
    postcode: account?.address.postcode ?? '',
    profile_pic: image,
  }

  useEffect(() => {
    setImage(`data:image/png;base64,${account?.profile_pic}` ?? '')
  }, [account])

  const [dob, setDob] = useState<Date>(new Date())
  const [isDirty, setIsDirty] = useState(false)
  const [editFields, setEditFields] = useState<Set<string>>(new Set())
  const [idImage, setIdImage] = useState<string>('')
  const [hasNewID, setHasNewID] = useState(false)

  const handleDateChange = (date: Date) => {
    setDob(date)
    setIsDirty(true)
  }

  const handleEditField = ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement>) => {
    const activeFields = editFields
    const fieldId = currentTarget.name
    if (activeFields.has(fieldId)) {
      activeFields.delete(fieldId)
    } else {
      activeFields.add(fieldId)
    }
    setEditFields(new Set(activeFields))
  }
  const verifyEditables = (name: string) => {
    return !editFields.has(name)
  }
  const {errors, register, handleSubmit, watch, setValue, formState} = useForm<
    AccountFormData
  >({
    defaultValues: accountData,
    resolver: yupResolver(AccountSchema),
  })
  const readFile = (
    file: File,
    setImg: Dispatch<SetStateAction<string>>,
    formValue: string,
  ) => {
    let img = ''
    const reader = new FileReader()
    reader.onloadend = () => {
      if (reader.result) {
        img = reader.result as string
        setImg(img)
        setValue(formValue, img)
      }
    }
    reader.readAsDataURL(file)
    return img
  }
  const handleIdImage = ({target}: ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      readFile(target.files[0] as File, setIdImage, 'doc_image')
      setIsDirty(true)
    }
  }
  const handleImageUpdate = ({target}: ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      readFile(target.files[0] as File, setImage, 'profile_pic')
      setIsDirty(true)
    }
  }
  const docType = watch('doc_type')

  useEffect(() => {
    if (docType?.toLowerCase() !== account?.indetification.name.toLowerCase()) {
      return setHasNewID(true)
    }
    return setHasNewID(false)
  }, [account, docType])

  return {
    dob,
    isDirty,
    errors,
    formState,
    hasNewID,
    image,
    idImage,
    handleSubmit,
    handleDateChange,
    handleImageUpdate,
    handleIdImage,
    handleEditField,
    register,
    setIsDirty,
    verifyEditables,
  }
}
