import React from 'react'
import {SvgIcon} from './SvgIcon'

export const LogOutIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'none',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      className={` ${strokeColor} ${className}`}
      viewBox="0 0 24 24"
    >
      <path d="M17.813 5.156q3.188 2.719 3.188 6.844 0 3.75-2.625 6.375t-6.375 2.625-6.375-2.625-2.625-6.375q0-1.688 0.938-3.703t2.25-3.141l1.406 1.406q-1.078 0.891-1.828 2.484t-0.75 2.953q0 2.906 2.039 4.945t4.945 2.039 4.945-2.039 2.039-4.945q0-1.359-0.75-2.953t-1.828-2.438zM12.984 3v9.984h-1.969v-9.984h1.969z" />
    </svg>
  )
}
