import React, {FC, useCallback, useState, useEffect} from 'react'
import {useStateMachine} from 'little-state-machine'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers'
import {history, toFormData, placeholderImage} from 'src/helpers'
import {FormError} from 'src/components'
import {useDispatch} from 'react-redux'
import {
  RegisterStore,
  updateAction,
  ProfilePictureSchema,
  ProfilePictureData,
} from './Register'
import {updateProfilePicture} from './registerActions'
// eslint-disable-next-line max-lines-per-function
export const RegisterStepThree: FC = () => {
  const {state, action} = useStateMachine<RegisterStore>(updateAction)
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer>('')
  const {register, handleSubmit, errors, watch} = useForm({
    defaultValues: state.data,
    resolver: yupResolver(ProfilePictureSchema),
  })
  const dispatch = useDispatch()
  const onSubmit = handleSubmit(async (values: ProfilePictureData) => {
    const formData = toFormData({
      profile_image: values.profile_image,
    })
    const isValid = await dispatch(updateProfilePicture(formData))
    if (((isValid as unknown) as boolean) === true) {
      action({
        ...values,
        profile_image: '',
      })

      history.push('/dashboard')
    }
  })

  const getImageMeta = useCallback((imageFile: File): void => {
    const reader = new FileReader()
    reader.onloadend = (): void => {
      if (reader.result) setImageSrc(reader.result)
    }

    reader.readAsDataURL(imageFile)
  }, [])

  const profile_image = watch('profile_image')

  const image = profile_image?.[0]

  useEffect(() => {
    if (image) getImageMeta(image)
  }, [getImageMeta, image])

  return (
    <section className="pt-4">
      <p className="font-bold pt-2">Profile Picture</p>
      <form onSubmit={onSubmit} className="max-w-sm mx-auto">
        {imageSrc ? (
          <div className="max-w-sm rounded rounded-full mx-auto mb-4 overflow-hidden shadow-lg">
            <img className="w-full" src={imageSrc as string} />
          </div>
        ) : (
          <div className="max-w-sm rounded rounded-full h-48 w-48 mx-auto mb-4 overflow-hidden shadow-lg">
            <img className="w-full" src={placeholderImage} />
          </div>
        )}
        <div className="mt-3">
          <label
            htmlFor="profile_image"
            className="w-1/2 inline-flex cursor-pointer justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-red active:bg-red-600 transition duration-150 ease-in-out"
          >
            Upload ID photo
          </label>
          <p
            className={`${
              errors?.profile_image?.['message'] ? 'inline' : 'hidden'
            } pl-8 text-sm text-center text-red-600`}
          >
            {errors?.profile_image?.['message'] || ''}
          </p>
        </div>
        <input
          id="profile_image"
          name="profile_image"
          type="file"
          accept="image/*"
          ref={register}
          className="hidden"
        />
        <div className="mt-3 flex items-center">
          <input
            id="termsOfUse"
            name="termsOfUse"
            type="checkbox"
            ref={register}
            className="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out"
          />
          <label
            htmlFor="termsOfUse"
            className="pl-4 block text-xs md:text-sm leading-5 text-gray-900"
          >
            I understand and agree to the Terms of Use.
          </label>
        </div>
        <FormError err={errors?.termsOfUse?.['message']} />
        <div className="mt-3 flex items-center">
          <input
            id="privacyPolicy"
            name="privacyPolicy"
            type="checkbox"
            ref={register}
            className="form-checkbox h-4 w-4 text-red-600 transition duration-150 ease-in-out"
          />
          <label
            htmlFor="privacyPolicy"
            className="pl-4 block text-xs md:text-sm leading-5 text-gray-900"
          >
            I understand and agree to the Privacy Policy.
          </label>
        </div>
        <FormError err={errors?.privacyPolicy?.['message']} />
        <div className="mt-4 flex justify between">
          <span className="block w-full rounded-md shadow-sm">
            <button
              type="button"
              onClick={() => history.push('/register/step-1')}
              className="w-full flex justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-600 focus:outline-none focus:border-gray-600 focus:shadow-outline-red active:bg-gray-600 transition duration-150 ease-in-out"
            >
              Back
            </button>
          </span>
          <div className="px-2" />

          <span
            className={`block w-full rounded-md shadow-sm ${
              imageSrc ? 'mb-12' : 'mb-0'
            }`}
          >
            <button
              type="submit"
              className="w-full flex justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-red active:bg-red-600 transition duration-150 ease-in-out"
            >
              Next
            </button>
          </span>
        </div>
      </form>
    </section>
  )
}
