// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {loadProgressBar} from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'
import React, {FC, useEffect} from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import {useDispatch} from 'react-redux'
import {Router} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import {ErrorBoundary} from 'src/components'
import {fetchAuthUser} from 'src/features/account'
import {getCookieValue, history} from 'src/helpers'
import {GlobalStyles, theme} from 'src/styles'
import {ThemeProvider} from 'styled-components'
import {AllRoutes} from './Routes'

const AppTheme: React.FC = ({children}) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

const App: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const authToken = getCookieValue('soarRisingToken')
    if (authToken) {
      dispatch(fetchAuthUser())
    }
  }, [dispatch])

  return (
    <AppTheme>
      <GlobalStyles />
      <ToastContainer />
      <ErrorBoundary>
        <section className="mx-auto h-screen overflow-x-hidden pb-12 relative">
          <Router history={history}>
            {loadProgressBar()}
            <AllRoutes />
          </Router>
        </section>
      </ErrorBoundary>
    </AppTheme>
  )
}

export default App
