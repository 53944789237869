import React, {ChangeEvent, FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CloseIcon, Heading, InputField, MainLayout} from 'src/components'
import {
  getSearchData,
  selectSearchData,
  selectSearchDataLoading,
} from './searchSlice'

export const SearchPage: FC = () => {
  const [searchInput, setSearchInput] = useState('')
  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }
  const dispatch = useDispatch()
  useEffect(() => {
    if (/\w+/gi.test(searchInput) && searchInput.length >= 1) {
      dispatch(getSearchData(searchInput))
    }
  }, [dispatch, searchInput])
  const searchResults = useSelector(selectSearchData)
  const searchResultsLoading = useSelector(selectSearchDataLoading)
  console.log('search', searchResults, searchResultsLoading)
  return (
    <MainLayout>
      <div className="flex w-full justify-between items-center">
        <Heading text="Search" />
        {searchInput.length >= 1 && (
          <button
            className="hover:bg-gray-100 p-2 rounded transition duration-150 ease-in-out"
            type="button"
            onClick={() => setSearchInput('')}
          >
            <CloseIcon
              className="text-gray-600 w-4 h-4"
              fillColor="currentColor"
            />
          </button>
        )}
      </div>
      <InputField
        name="searchInput"
        type="search"
        value={searchInput}
        onChange={handleSearchInput}
      />
      <button className="block text-center py-4 px-6 w-full rounded-md border font-medium 1 bg-red-600 border-red-600 text-white my-2">
        Search
      </button>

      {Array(6)
        .fill('abc')
        .map(i => (
          <div key={i} className="px-4 py-6 border-b">
            {i}
          </div>
        ))}
    </MainLayout>
  )
}
