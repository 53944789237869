import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RewardData, fetchRewardsData} from 'src/api/rewardsAPI'
import {AppThunk, RootState} from 'src/app/store'

export interface RewardsState {
  data?: RewardData
  loading: boolean
  error: string | null
}
const initialState: RewardsState = {
  data: undefined,
  loading: true,
  error: null,
}

const rewards = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    getRewardsDataStart(state) {
      return {
        ...state,
        loading: true,
        error: null,
      }
    },
    getRewardsDataSuccess(state, action: PayloadAction<RewardData>) {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      }
    },
    getRewardsDataFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    },
    postReferUserStart(state) {
      return {
        ...state,
        loading: true,
        error: null,
      }
    },
    postReferUserFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    },
  },
})

export const {
  getRewardsDataStart,
  getRewardsDataSuccess,
  getRewardsDataFailure,
  postReferUserStart,
} = rewards.actions
export default rewards.reducer

export const getRewardsData = (): AppThunk => async dispatch => {
  try {
    dispatch(getRewardsDataStart())
    const data = await fetchRewardsData()
    dispatch(getRewardsDataSuccess(data))
  } catch (err) {
    dispatch(getRewardsDataFailure(err))
  }
}

// export const referUser = (referData: FormData): AppThunk => async dispatch => {
//   try {
//     dispatch(postReferUserStart())
//     const res = await postReferFriend(referData)
//     toast.success(res.data.message)
//   } catch (err) {
//     dispatch(getRewardsDataFailure(err))
//   }
// }

/**
 * selectors
 **/
const getRewardsState = (state: RootState) => state.rewards

export const selectRewardsDataLoading = createSelector(
  getRewardsState,
  rewardsStore => rewardsStore.loading,
)
export const selectRewardsData = createSelector(
  getRewardsState,
  rewardsStore => rewardsStore.data,
)
