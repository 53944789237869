import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AuthUser, deleteUser, fetchUser, updateUser} from 'src/api/authAPI'
import {AppThunk} from 'src/app/store'
import {toast} from 'react-toastify'

interface AuthUserState {
  data: AuthUser | undefined
  loading: boolean
  error: string | null
}

const initialState: AuthUserState = {
  data: undefined,
  loading: false,
  error: null,
}

const authUser = createSlice({
  name: 'authProfile',
  initialState,
  reducers: {
    authUserStart(state) {
      state.loading = true
      state.error = null
    },
    authUserSuccess(state, action: PayloadAction<AuthUser>) {
      state.data = action.payload
      state.loading = false
      state.error = null
    },
    authUserFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    deleteUserStart(state) {
      state.loading = true
      state.error = null
      state.data = undefined
    },
    deleteUserSuccess(state, action: PayloadAction<undefined>) {
      state.data = action.payload
      state.loading = false
      state.error = null
    },
    deleteUserFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
    updateUserStart(state) {
      state.error = null
    },
    updateUserFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const {
  authUserStart,
  authUserSuccess,
  authUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  updateUserStart,
  updateUserFailure,
} = authUser.actions
export default authUser.reducer

export const fetchAuthUser = (): AppThunk => async dispatch => {
  try {
    dispatch(authUserStart())
    const userProfile = await fetchUser()
    dispatch(authUserSuccess(userProfile))
  } catch (err) {
    dispatch(authUserFailure(err))
  }
}
export const updateAuthUser = (data: FormData): AppThunk => async dispatch => {
  try {
    dispatch(updateUserStart())
    const res = await updateUser(data)
    toast.success(res.data.message)
  } catch (err) {
    dispatch(updateUserFailure(err))
  }
}

export const deleteAuthUser = (): AppThunk => async dispatch => {
  try {
    dispatch(deleteUserStart())
    await deleteUser()
    dispatch(deleteUserSuccess(undefined))
  } catch (err) {
    dispatch(deleteUserFailure(err))
  }
}
