import React, {FC, useState} from 'react'
import {Opportunity} from 'src/api/bondsAPI'
import {currencyFormatter} from 'src/helpers'
import {InvestmentForm} from './InvestmentForm'

export const OpportunityInvestmentCard: FC<{
  opportunity: Opportunity
  bondId: string
}> = ({
  opportunity: {
    interest,
    opportunity_type,
    status,
    progress,
    months_to_mature,
    investment_raised_to_date,
    active_investors,
    image,
    days_left,
    description,
    unit_price,
    unit_remaining,
    video_link,
    video_type,
    name,
  },
  bondId,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const openInvestmentForm = () => {
    setIsOpen(true)
  }
  const closeInvestmentForm = () => {
    setIsOpen(false)
  }

  return (
    <section
      className={`${
        isOpen ? 'flex-col lg:flex-row lg:max-w-4xl' : 'flex-col'
      } flex w-full md:mx-auto md:max-w-sm bg-gray-100 rounded-lg shadow`}
    >
      <div className="max-w-sm">
        <div className="flex">
          <div className="relative flex-1">
            <video
              controls
              className="h-56 w-full rounded-t-lg object-cover"
              src={
                video_link === 'undefined'
                  ? undefined
                  : video_type === 'youtube'
                  ? `https://www.youtube.com/embed/${video_link}`
                  : video_link
              }
              poster={
                image
                  ? image
                  : 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Townhouses_in_Victoria_Australia.jpg/220px-Townhouses_in_Victoria_Australia.jpg'
              }
            />
            <span
              className={`absolute left-0 top-0 inline-flex items-center mt-3 ml-3 px-3 py-1 rounded-full text-xs font-medium leading-4 ${
                opportunity_type === 'charity'
                  ? 'bg-yellow-500 text-black'
                  : 'bg-red-600 text-white'
              }`}
            >
              {opportunity_type === 'charity'
                ? 'Charity'
                : `Investment - ${interest}% interest`}
            </span>
            <span className=" absolute top-0 right-0 inline-flex items-center mt-3 mr-3 px-3 py-1 rounded-full text-xs font-medium leading-4 bg-red-600 text-white">
              {status}
            </span>
          </div>
        </div>
        <div className="w-full overflow-x-hidden">
          <div className="shadow w-full bg-grey-500 h-5">
            <div
              className="bg-gray-500 text-xs leading-none h-5 py-1 text-center text-white"
              style={{
                width: `${progress ? (progress > 100 ? 100 : progress) : 0}%`,
              }}
            >
              <span>{progress}%</span>
            </div>
          </div>
        </div>
        <div className="text-sm py-3">
          <div className="-mt-px leading-snug px-3 font-bold grid grid-cols-5 grid-rows-1">
            <p className="pr-1 font-light text-gray-900">Months to mature</p>
            <p className="px-1 col-span-2 font-light text-gray-900">
              {opportunity_type === 'charity' ? 'Donations' : `Investment`}{' '}
              raised to date
            </p>
            <p className="px-1 font-light text-gray-900">
              Active {opportunity_type === 'charity' ? 'donors' : `investors`}
            </p>
            <p className="px-1 font-light text-gray-900">Days left</p>
          </div>
          <div className="-mt-px leading-5 px-3 font-bold grid grid-cols-5 grid-rows-1">
            <p className="pr-1">{months_to_mature}</p>
            <p className="px-1 col-span-2">
              {currencyFormatter.format(investment_raised_to_date)}
            </p>
            <p className="px-1">{active_investors}</p>
            <p className="px-1">{days_left}</p>
          </div>
          <p className="px-3 pt-4 text-gray-800">{description}</p>
        </div>
      </div>
      {isOpen ? (
        <div>
          <div className="pt-5 pb-3">
            <div className="-mt-px text-sm leading-snug px-3 font-bold grid grid-cols-2 grid-rows-1">
              <p className="pr-1 font-light text-gray-900">Units price</p>
              <p className="px-1 font-light text-gray-900">Units remaining</p>
            </div>
            <div className="-mt-px leading-5 px-3 font-bold grid grid-cols-2 grid-rows-1">
              <p className="pr-1">{currencyFormatter.format(unit_price)}</p>
              <p className="px-1">{unit_remaining}</p>
            </div>
          </div>
          <InvestmentForm
            bondId={bondId}
            unitPrice={unit_price}
            investmentName={name}
            investmentImage={image}
            unitRemaining={unit_remaining}
            closeForm={closeInvestmentForm}
          />
        </div>
      ) : (
        <div className="py-4">
          <span className="block w-11/12 mx-auto rounded-md shadow-sm">
            <button
              type="button"
              onClick={openInvestmentForm}
              className="w-full flex justify-center p-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-600 focus:outline-none focus:border-red-600 focus:shadow-outline-red active:bg-red-600 transition duration-150 ease-in-out"
            >
              Start {opportunity_type === 'charity' ? 'donating' : `investing`}
            </button>
          </span>
        </div>
      )}
    </section>
  )
}
