import React, {FC, useEffect, useCallback} from 'react'
import {MainLayout, ExportIcon, OptionsIcon} from 'src/components'
import {Heading} from 'src/components/ui/Heading'
import {useDispatch, useSelector} from 'react-redux'
import {range} from 'src/helpers'
import {
  getBondsHistory,
  selectBondsHistory,
  selectBondsHistoryLoading,
} from '../dashboard/dashboardSlice'
import {BondDetailsCard, SkeletonBondDetailsCard} from './BondDetailsCard'

export const BondsPage: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getBondsHistory())
  }, [dispatch])
  const bondsHistory = useSelector(selectBondsHistory)
  const bondsHistoryLoading = useSelector(selectBondsHistoryLoading)
  const renderBondsList = useCallback(() => {
    if (bondsHistoryLoading && (!bondsHistory || !bondsHistory.length))
      return range(0, 3).map(val => <SkeletonBondDetailsCard key={val} />)
    if (!bondsHistory || !bondsHistory.length)
      return (
        <div className="flex justify-center">
          <p>No bond history</p>
        </div>
      )
    return bondsHistory.map(bond => (
      <BondDetailsCard key={bond.id} bond={bond} />
    ))
  }, [bondsHistory, bondsHistoryLoading])
  return (
    <MainLayout>
      <section className="flex justify-between">
        <Heading text="Bonds" />
        <section className="flex justify-between">
          <div className="flex items-center mr-6 text-gray-900">
            <h1 className="my-0 text-base mr-3">Export</h1>
            <div>
              <ExportIcon strokeColor="current-color" />
            </div>
          </div>
          <div className="flex items-center text-gray-900">
            <h1 className="my-0 text-base mr-3">Filter</h1>
            <div>
              <OptionsIcon strokeColor="current-color" />
            </div>
          </div>
        </section>
      </section>
      <section className="flex flex-col items-start space-y-4 md:space-x-4 md:space-y-0 md:flex-row pt-6">
        {renderBondsList()}
      </section>
    </MainLayout>
  )
}
