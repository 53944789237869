import React, {FC} from 'react'
import styled from 'styled-components'

const HorizontalScrollContainer = styled.ul`
  grid-gap: 1rem;
  grid-template-columns: 18rem;
  grid-auto-columns: 18rem;
  overflow-x: auto;
  scroll-snap-type: x proximity;
`

export const HorizontalScroll: FC<{classNames?: string}> = ({
  children,
  classNames = '',
}) => {
  return (
    <HorizontalScrollContainer
      className={`grid grid-flow-col py-4 ${classNames}`}
    >
      {children}
    </HorizontalScrollContainer>
  )
}
