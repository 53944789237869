import React from 'react'
import {Redirect, Route, RouteComponentProps} from 'react-router-dom'
import {getCookieValue} from 'src/helpers'

interface Props {
  component: React.FC<RouteComponentProps>
  path: string | string[]
  exact?: boolean
}

const PrivateRoute: React.FC<Props> = ({component: Page, ...rest}) => {
  const authToken = getCookieValue('soarRisingToken')
  const renderPrivateComponent: React.FC<RouteComponentProps> = props => {
    if (authToken) {
      return <Page {...props} />
    }
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {from: props.location.pathname},
        }}
      />
    )
  }

  return <Route {...rest} render={renderPrivateComponent} />
}

export default PrivateRoute
