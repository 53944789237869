import React, {FC} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  CancelIcon,
  LoadingSpinner,
  LogOutIcon,
  MainLayout,
} from 'src/components'
import {logOutUser} from '../login'
import {selectAuthProfile, selectAuthProfileLoading} from './accountSelector'
import {deleteAuthUser, updateAuthUser} from './accountSlice'
import {EditAccountForm} from './EditAccountForm'

export const AccountPage: FC = () => {
  const dispatch = useDispatch()
  const account = useSelector(selectAuthProfile)
  const isLoading = useSelector(selectAuthProfileLoading)

  const submitForm = (data: FormData) => {
    dispatch(updateAuthUser(data))
  }

  return (
    <MainLayout>
      <div className="w-full flex flex-col lg:items-center">
        <div>
          {isLoading ? (
            <div className="mt-6">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <EditAccountForm account={account} submitForm={submitForm} />
              <a
                href={account?.agreement_link ?? '/'}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-center py-4 px-6 w-full rounded-md border font-medium 1 bg-red-600 border-red-600 text-white my-2"
              >
                Download user agreement
              </a>
              <div className="flex justify-between text-gray-700 my-2">
                <button
                  className="flex font-medium"
                  type="button"
                  onClick={() => dispatch(logOutUser())}
                >
                  Logout
                  <LogOutIcon
                    className="mx-2"
                    strokeColor="text-gray-500"
                    fillColor="currentColor"
                  />
                </button>
                <button
                  className="flex font-medium"
                  type="button"
                  onClick={() => dispatch(deleteAuthUser())}
                >
                  Delete account
                  <CancelIcon className="mx-2" strokeColor="text-gray-500" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </MainLayout>
  )
}
