import {FAQ} from 'src/features/faq/types'
import axiosInstance from './axiosInstance'

export interface User {
  login: string
  avatar_url: string
}

export type SingleFAQResponse = {data: Array<FAQ>}
export type FAQResponse = {
  data: Array<Omit<FAQ, 'description'>>
}

export async function fetchFAQs(): Promise<FAQResponse> {
  const {data} = await axiosInstance.get<FAQResponse>('api/faq-list')
  return data
}
export async function fetchSingleFAQ(id: string): Promise<SingleFAQResponse> {
  const {data} = await axiosInstance.post<SingleFAQResponse>('api/faq-single', {
    id,
  })
  return data
}
