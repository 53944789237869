import axiosInstance from './axiosInstance'

export type RewardData = {
  return: number
  returns_to_date: number
  reward: number
  total_invested: number
  'wallet balance': number
}

export async function fetchRewardsData(): Promise<RewardData> {
  const {
    data: {
      data: [reward],
    },
  } = await axiosInstance.get<{data: Array<RewardData>}>(
    '/api/investment-overview',
  )
  return reward
}

// export async function postReferFriend(
//   referralCred: FormData,
// ): Promise<ReferFriendResponse> {
//   const {data} = await axiosInstance.post<ReferFriendResponse>(
//     '/api/invite-friend',
//     referralCred,
//   )
//   return data
// }
