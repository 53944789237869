import React, {ReactNode, FC} from 'react'
import {Footer} from '../footer'
import {Header} from '../header'

export const MainLayout: FC<{children: ReactNode[] | ReactNode}> = ({
  children,
}) => (
  <>
    <Header />
    <section className="pt-20 px-4 md:px-6 lg:px-8 block h-full overflow-y-auto">
      {children}
    </section>
    <Footer />
  </>
)
