import axios from 'axios'
import {toast} from 'react-toastify'
import {getCookieValue} from 'src/helpers'

const publicRoutes: Array<string> = []

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://referral.soarising.com'
axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

axios.interceptors.request.use(request => {
  if (publicRoutes.includes(request?.url as string)) {
    return request
  }
  const token = getCookieValue('soarRisingToken')
  if (token) {
    request.headers.Authorization = `Token ${token}`
  }
  return request
})
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (axios.isCancel(error)) {
      console.log('Request cancelled...')
      return Promise.reject(error)
    }
    // Timeout
    if (error.code === 'ECONNABORTED') {
      toast.error(
        'Your request has timed out. Check your internet connection and try again.',
      )
      return Promise.reject(error)
    }

    let errorValue = error
    if ((!error.response || !error.response.data) && error.message) {
      errorValue = error.message
    }
    errorValue = error.response.data
    if (errorValue.message) {
      errorValue = errorValue.message
    }
    if (errorValue[0] && errorValue[0].message) {
      errorValue = errorValue[0].message
    }
    toast.error(errorValue)

    return Promise.reject(error)
  },
)

export default axios
