import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {fetchSearchData} from 'src/api/searchApi'
import {AppThunk, RootState} from 'src/app/store'

export interface SearchState {
  data?: unknown
  loading: boolean
  error: string | null
}
const initialState: SearchState = {
  data: undefined,
  loading: true,
  error: null,
}

const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    getSearchDataStart(state) {
      return {
        ...state,
        loading: true,
        error: null,
      }
    },
    getSearchDataSuccess(state, action: PayloadAction<unknown>) {
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      }
    },
    getSearchDataFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    },
  },
})

export const {
  getSearchDataStart,
  getSearchDataSuccess,
  getSearchDataFailure,
} = search.actions
export default search.reducer

export const getSearchData = (
  searchInput: string,
): AppThunk => async dispatch => {
  try {
    dispatch(getSearchDataStart())
    const data = await fetchSearchData(searchInput)
    dispatch(getSearchDataSuccess(data))
  } catch (err) {
    dispatch(getSearchDataFailure(err))
  }
}

/**
 * selectors
 **/
const getSearchState = (state: RootState) => state.search

export const selectSearchDataLoading = createSelector(
  getSearchState,
  searchStore => searchStore.loading,
)
export const selectSearchData = createSelector(
  getSearchState,
  searchStore => searchStore.data,
)
