import React from 'react'
import {SvgIcon} from './SvgIcon'

export const CancelIcon: SvgIcon = ({
  width = 20,
  height = 20,
  strokeColor = 'text-black',
  fillColor = 'none',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-current ${strokeColor} ${className}`}
      viewBox="0 0 57 57"
    >
      <circle
        cx="28.5"
        cy="28.5"
        r="27"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        d="M17 20.2677L20.2677 17V17.0045L28.8454 25.5868L37.4231 17L40.684 20.2677L32.1063 28.8454L40.6908 37.4231L37.4231 40.684L28.8454 32.1063L20.2722 40.6908L17.0045 37.4231L25.5868 28.8454L17 20.2677Z"
        fill="currentColor"
      />
    </svg>
  )
}
