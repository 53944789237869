import React, {ChangeEvent, forwardRef, RefObject} from 'react'

interface InputProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  name: string
  className?: string
  defaultValue?: string
  value?: string
  placeholder?: string
  type?: string
  readOnly?: boolean
}

const Input = (
  {
    name,
    className,
    value,
    onChange,
    placeholder,
    type = 'text',
    readOnly,
  }: InputProps,
  ref:
    | ((instance: HTMLInputElement | null) => void)
    | RefObject<HTMLInputElement>
    | null,
) => {
  return (
    <input
      ref={ref}
      readOnly={readOnly}
      onChange={onChange}
      value={value}
      type={type}
      name={name}
      placeholder={placeholder}
      className={`appearance-none text-black font-semibold bg-gray-100 placeholder-gray-400 w-full p-4 rounded-lg my-1 ${className}`}
    />
  )
}

export const InputField = forwardRef<HTMLInputElement, InputProps>(Input)
